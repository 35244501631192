import React from "react";
import {IModalProps} from "../../../../libs/uiKit/modal/types";
import {Modal} from "../../../../libs/uiKit/modal";
import {Text4} from "../../../../libs/uiKit/globalStyledComponents";
import Button from "../../../../libs/uiKit/button";
import {StyledModalTwoButtonsGroup, StyledTextModalWrapper} from "../../../../libs/uiKit/modal/styles";

const finalWarningModal = (props: IModalProps & { createAgreement: boolean }) => {
  const { isOpen, onClose, isLoading, createAgreement } = props;

  return (
    <StyledTextModalWrapper>
      <Modal isOpen={isOpen} onClose={() => onClose()} header="Сохранение">
        <Text4>Сохранение расширенной заявки {createAgreement && "и договора"} может занять продолжительное время. Пожалуйста, подождите завершения процесса сохранения заявки.</Text4>
        <StyledModalTwoButtonsGroup>
          <Button isLoading={isLoading} type='submit' appearance='filled'>
            Создать
          </Button>
          <Button onClick={onClose} isLoading={isLoading} appearance='transparent'>
            Отмена
          </Button>
        </StyledModalTwoButtonsGroup>
      </Modal>
    </StyledTextModalWrapper>
  );
}

export default React.memo(finalWarningModal);