import React, { createElement } from 'react';
import { findAll } from 'highlight-words-core';

const HighlightPattern: React.FC<{ textToHighlight: string; source: string }> = ({ textToHighlight, source }) => {
	const sourceReg = source.replace(/[^a-zа-яё\s]/gi, '');

	const searchWords = sourceReg.split(/[ ,]/).filter(n => n);

	const chunks = findAll({
		searchWords,
		textToHighlight,
		caseSensitive: false,
	});

	return createElement(
		'span',
		{},
		chunks.map((chunk, index) => {
			const text = textToHighlight.substr(chunk.start, chunk.end - chunk.start);
			if (chunk.highlight) return createElement('mark', { className: 'highlight', key: index }, text);
			return createElement('span', { key: index }, text);
		})
	);
};

export default HighlightPattern;
