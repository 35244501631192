import React from 'react';
import { useCreateGroupContext } from './context';
import { Modal } from '../../../../../../libs/uiKit/modal';
import { FieldsBuilder } from '../../../../../../libs/uiKit/fieldsBuilder';
import { StyledButtonsGroup } from './styles';
import Button from '../../../../../../libs/uiKit/button';
import {TimeReloadIcon} from "../../../../../../icons/ui/TimeReload";
import ChangeLogModal from "../../changeLogModal";

const CreateGroupUi: React.FC = () => {
	const { isOpen, onClose, header, isEdit, form, isLoadingUpdateGroup, onFormSubmit, id, name, isDisabledEdit } = useCreateGroupContext();

	const [stateIsOpenHistory, setIsOpenHistory] = React.useState<boolean>(false);

	return (
		<>
			<Modal width={60} isOpen={isOpen} onClose={onClose} header={header}
						 afterHeaderContent={isEdit ? <Button tooltip="История изменений" onClick={() => setIsOpenHistory(true)} appearance="icon"
																									icon={<TimeReloadIcon/>} style={{display: "inline"}} /> : null}
			>
				<form onSubmit={onFormSubmit}>
					<FieldsBuilder {...form} />
					<StyledButtonsGroup>
						<Button type='submit' isLoading={isLoadingUpdateGroup} isDisabled={isDisabledEdit} appearance='filled'>
							{isEdit ? 'Сохранить' : 'Создать группу'}
						</Button>
						<Button onClick={onClose} isLoading={isLoadingUpdateGroup} appearance='transparent'>
							Отмена
						</Button>
					</StyledButtonsGroup>
				</form>
			</Modal>
			<ChangeLogModal
				onClose={() => setIsOpenHistory(false)}
				isOpen={stateIsOpenHistory}
				data={{id, model: 'GROUP'}}
				header={`Изменения группы ${name}`}
			/>
		</>
	);
};

export default React.memo(CreateGroupUi);
