import React, {useState} from 'react';
import {useQuery} from "@apollo/react-hooks";
import {useNavigate, useParams} from "react-router-dom";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {useMutation} from "@apollo/client";
import dayjs from "dayjs";
import MainLayoutIgniter from "../common/mainLayout";
import {Heading2, Heading3, Heading4, Text4} from "../../../libs/uiKit/globalStyledComponents";
import {
	StyledAgreementNumber,
	StyledButtonsGroupAutoSize, StyledContractDocuments,
	StyledContractHeader, StyledContractRegistrationForm,
	StyledContractWrapper,
	StyledEventsButtons, StyledHeaderInformation,
	StyledHeaderWrapper, StyledImportantNumber, StyledInformationItem, StyledRisksInformation,
	StyledSignetAgreementButtons, StyledTotalInformation, StyledTotalInformationItem
} from "./ui/styles";
import Button from "../../../libs/uiKit/button";
import {DownloadIcon} from "../../../icons/ui/Download";
import FlyButtons from "../../../libs/uiKit/flyButtons";
import {FieldsBuilder} from "../../../libs/uiKit/fieldsBuilder";
import ContragentIgniter from "../createAgreement/widgets/contragent";
import RiskLifeIgniter from "./widgets/riskLife";
import RiskPropertyIgniter from "./widgets/riskProperty";
import RiskTitleIgniter from "./widgets/riskTitle";
import {StyledTableAreaWrapper} from "../underwriting/ui/styles";
import Table from "../../../libs/uiKit/table";
import DocumentsWidget from "../../widgets/documents";
import ProlongationModal from "./widgets/ProlongationModal";
import {useGlobalContext} from "../../../apps/main/ui/GlobalContext";

import useGetDicti from "../common/hooks/useGetDicti";
import {
	AgreementToDraftMutation, AgreementToDraftMutationVariables,
	ChangeAgreementMutation,
	ChangeAgreementMutationVariables,
	ContragentFragment,
	DictKeyEnum,
	LoadContractQuery,
	LoadContractQueryVariables,
	PrintMutation, PrintMutationVariables,
	PrintTemplateEnum,
	PrintTypeEnum,
	SignAgreementMutation,
	SignAgreementMutationVariables, UnsignAgreementMutation, UnsignAgreementMutationVariables
} from "../../../libs/api/graphqlTypes";
import {useUserRights} from "../common/hooks/useUserRights";
import useNotification from "../../widgets/notifier/ui/hooks/useNitification";
import {AgreementId} from "../createAgreement/ui/context";
import {LOAD_CONTRACT} from "../../../libs/api/queries";
import {SBERBANK_ISN} from "../../../libs/utils/staticData";
import {
	AGREEMENT_TO_DRAFT,
	CHANGE_AGREEMENT,
	PRINT_MUTATION,
	SIGN_CONTRACT,
	UNSIGN_CONTRACT
} from "../../../libs/api/commands";
import {formatContractDataToFormData, setDataToContractForm} from "./ds/repositories/commands";
import {formatPersonDataToDataForm} from "../createAgreement/ds/repositories/commands";
import {formatDateToStringRu} from "../../../libs/utils/gormatDateToUsa";
import {getClearSelectValue} from "../../../libs/utils/getSelectdefaultValue";
import {IDropdownItem} from "../../../libs/uiKit/dropdownMenu/types";
import LoadingString from "../../../libs/uiKit/loadingString";
import Status from "../../../libs/uiKit/status";
import {stringToMoney} from "../../../libs/uiKit/utils";
import {IForm} from "../../../libs/uiKit/fieldsBuilder/types";
import {ContragentField} from "../createAgreement/widgets/contragent/ui/context";
import {RiskLifeField} from "./widgets/riskLife/ui/context";
import {RiskPropertyField} from "./widgets/riskProperty/ui/context";
import {RiskTitleField} from "./widgets/riskTitle/ui/context";
import {IDaDataValue} from "../../../libs/uiKit/daData/types";
import {ISelectItem} from "../../../libs/uiKit/select/types";
import PayPeriodModal from "./widgets/PayPeriodModal";
import {fileDownloader} from "../../../libs/utils/fileDownloader";
import DeclarationModal from "../createAgreement/widgets/declarationModal";
import DropdownMenu from "../../../libs/uiKit/dropdownMenu";
import {SelectArrowIcon} from "../../../libs/uiKit/icons/SelectArrow";
import ChangeAddModal from "../invoice/widgets/ChangeAddModal";
import ChangeRemoveModal from "../invoice/widgets/ChangeRemoveModal";
import ChangeRecalcModal from "../invoice/widgets/ChangeRecalcModal";
import CrossModal from "../invoice/widgets/CrossModal";
import DiscountIgniter from "../seller/widgets/discount";
import checkbox from "../../../libs/uiKit/checkbox";
import AgreementAnketaModal from "./widgets/AgreementAnketaModal";
import {TimeReloadIcon} from "../../../icons/ui/TimeReload";
import {useWindowWidth} from "@react-hook/window-size";
import {MoreIcon} from "../../../icons/ui/More";
import AgreementChangeLogIgniter from "../../widgets/agreementChangeLog";

type InformationItem = { title: string; description: React.ReactNode };

export type ContractForm = {
	contragents: ContragentField[];
	risksLife: RiskLifeField[];
	risksProperty: RiskPropertyField[];
	risksTitle: RiskTitleField[];
	contractNumber: string;
	creditNumber: string;
	dateSign: Date | null;
	borrower: IDaDataValue | null;
	dateBeg: Date | null;
	creditTermsInfo: number | undefined;
	creditTerms: number | undefined;
	dateEnd: Date | null;
	exactDateEnd: Date | null;
	curIsn: string | undefined;
	annuityPay?: number;
	insureType: ISelectItem | null;
	prevInsuranceCompany: ISelectItem | null;
	creditOrganizationName: string | undefined;
	summ: number | undefined;
	creditRate: number | undefined;
	creditIssueDate: Date | null;
	firstPayDate: Date | null;
	agreementDate: Date | null;
	salesChannel: ISelectItem | null;
	useExactDateEnd: boolean;
};

const formatFormPersonDataToBackPerson = (person: ContragentField): ContragentFragment => ({
	fio: person.fullName?.value || null,
	birthday: person.birthDate ? formatDateToStringRu(person.birthDate) : null,
	registrationAddressIsActual: person.addressRadio === 'true',
	phone: person.phoneMobile || null,
	email: person.email || null,
	sex: person.gender || null,
	registrationAddress: person?.addr1 || null,
	actualAddress: person.addr2 || null,
	passportSeries: person.authDocSer || null,
	passportNumber: person.authDocNumber || null,
	passportIssueDate: person.authDocDate ? formatDateToStringRu(person.authDocDate) : null,
	passportDevisionCode: person.authDocIssuerCode || null,
	passportIssuedBy: person.authDocIssuer || null,
});

type HeaderButton = { label: string; event: () => void; icon: React.ReactNode; isLoading?: boolean; isDisabled?: boolean };

const ContractPage: React.FC = () => {

	const windowWidth = useWindowWidth({ wait: 300 });

	const {
		user: {
			isSuper,
			restrictions: { contragentMaxAge },
		},
		routes: { createAgreementStep, error500, agreementByIdSeller, contractById, invoiceById, contractAnketaById },
	} = useGlobalContext();
	const navigate = useNavigate();
	const formHook = useForm<ContractForm>({ mode: 'onBlur' });
	const { data: selectAgreementTypes, loading: loadingAgreementTypes } = useGetDicti(DictKeyEnum.AgreementTypes);
	const { data: selectSalesChannel, loading: loadingSalesChannel } = useGetDicti(DictKeyEnum.SaleChannel);
	const { data: selectPrevSk, loading: loadingPrevSk } = useGetDicti(DictKeyEnum.PrevSk);
	const { id } = useParams<{ id?: string }>();
	const { rightsHandler } = useUserRights();
	const { setNotification } = useNotification();
	const [ stateIsDeclarationWay, setStateIsDeclarationWay ] = React.useState<boolean>(false);

	const [stateChangeLogModalIsOpen, setChangeLogModalIsOpen] = React.useState<boolean>(false);

	const [downloadDoc, { loading: loadingDownloadDoc }] = useMutation<PrintMutation, PrintMutationVariables>(PRINT_MUTATION, {
		fetchPolicy: 'network-only',
	});

	const { fields: fieldsContragents, update } = useFieldArray({
		control: formHook.control,
		name: 'contragents',
	});

	const { fields: fieldsRisksLife } = useFieldArray({
		control: formHook.control,
		name: 'risksLife',
	});
	const { fields: fieldsRisksProperty } = useFieldArray({
		control: formHook.control,
		name: 'risksProperty',
	});
	const { fields: fieldsRisksTitle } = useFieldArray({
		control: formHook.control,
		name: 'risksTitle',
	});

	const [stateAddRiskModalIsOpen, setAddRiskModalIsOpen] = React.useState<boolean>(false);

	const [stateProlongationModalIsOpen, setProlongationModalIsOpen] = React.useState<boolean>(false);
	const [statePayPeriodModalIsOpen, setPayPeriodModalIsOpen] = React.useState<boolean>(false);
	const [stateDeclModalIsOpen, setDeclModalIsOpen] = useState<boolean>(false);
	const [stateDeclAnkModalIsOpen, setDeclAnkModalIsOpen] = useState<boolean>(false);
	const [stateChangeAddModalIsOpen, setChangeAddModalIsOpen] = useState<boolean>(false);
	const [stateChangeRemoveModalIsOpen, setChangeRemoveModalIsOpen] = useState<boolean>(false);
	const [stateChangeRecalcModalIsOpen, setChangeRecalcModalIsOpen] = useState<boolean>(false);
	const [stateDiscountModalIsOpen, setDiscountModalIsOpen] = useState<boolean>(false);
	const [stateCrossModal, setStateCrossModal] = useState<boolean>(false);

	const contractId: AgreementId = Number.isNaN(Number(id)) ? null : Number(id);

	const { data: dataContract, loading: loadingContract } = useQuery<LoadContractQuery, LoadContractQueryVariables>(LOAD_CONTRACT, {
		variables: { id: contractId },
		onError: error => setNotification({ text: error, type: 'error' }),
		fetchPolicy: 'network-only',
	});

	const headerButtons: HeaderButton[] = React.useMemo(
		() => [
			{
				label: 'История изменений',
				icon: <TimeReloadIcon />,
				event: () => setChangeLogModalIsOpen(true),
			},
		],
		[]
	);

	const {
		status,
		added,
		number,
		user,
		relatedCalculationId: agreementId,
		agreement,
		needProlongation,
		nextAgreementIsn,
		prevAgreementId,
		prevAgreementIsn,
		isProlongation,
		nextInvoiceAllowed,
		isCurrentPeriodPayed,
		nextInvoiceSum,
		isn: contractIsn,
		invoices,
		agreementDeclarationDone,
		isExpressMode,
		prevAgreementAnketaIsn,
		agreementAnketaIsn,
		agreementAnketaEdit
	} = dataContract?.loadAgreement || {};

	const { life_risks, property_risks, titul_risks, credit_bank_isn, agr_role, is_payed: isPayed, premium_sum, premium_sum_with_kv, discount_kv, seller_discount_due_kv, has_cross_sale, is_declaration, agent_kv_sum } = agreement || {};

	const isSberbank: boolean = credit_bank_isn === SBERBANK_ISN;

	const [insureTypeValue, useExtactEndDate, creditTermsInfo, dateBeg, dateEnd, exactDateEnd]
		= formHook.watch(['insureType', 'useExactDateEnd', 'creditTermsInfo', 'dateBeg', 'dateEnd', 'exactDateEnd']);

	const isHiddenAnnuityPay: boolean = ['1', '3'].includes(insureTypeValue?.value || '') || isSberbank;
	const isChangeInsuraceCase = insureTypeValue?.value === '4';

	const isRecalcNeeded: boolean = formHook.formState.isDirty;
	//const isRecalcNeeded: boolean = !readyToSign;

	const isCanceled: boolean = ['annul'].includes(status?.value || '');

	const isSigned: boolean = status?.value === 'signed';

	const isDisabledWithoutRecalc: boolean = isCanceled || isRecalcNeeded;

	const isDisabledGoToInvoice: boolean = isCanceled || !rightsHandler('invoicePage');

	const isShowKv: boolean = !!agent_kv_sum;

	const isDeclarationWay = isProlongation || isExpressMode || is_declaration;
	const isDeclarationWayWithAnketa =(isProlongation && prevAgreementAnketaIsn);
	const isAllowedToSkipDeclaration = isProlongation && !rightsHandler('prolongationNeedVerify');

	const [changeAgreement, {loading: loadingChangeAgreement}] = useMutation<ChangeAgreementMutation, ChangeAgreementMutationVariables>(CHANGE_AGREEMENT, {
		refetchQueries: ['loadContract'], awaitRefetchQueries: true
	});

	const [signContract, {loading: loadingSignContract}] = useMutation<SignAgreementMutation, SignAgreementMutationVariables>(SIGN_CONTRACT, {
		refetchQueries: ['loadContract'], awaitRefetchQueries: true
	});

	const [unsignContract, {loading: loadingUnsignContract}] = useMutation<UnsignAgreementMutation, UnsignAgreementMutationVariables>(UNSIGN_CONTRACT, {
		refetchQueries: ['loadContract'], awaitRefetchQueries: true
	});

	const [agreementToDraft, {loading: loadingAgreementToDraft}] = useMutation<AgreementToDraftMutation, AgreementToDraftMutationVariables>(AGREEMENT_TO_DRAFT, {
		refetchQueries: ['loadContract'], awaitRefetchQueries: true
	});

	const isLoading: boolean = loadingContract || loadingChangeAgreement || loadingSignContract;

	const isEditable = !isSigned && !isCanceled && !isLoading;

	React.useEffect(() => {
		if (!dataContract) return;
		const dataToSet = formatContractDataToFormData(dataContract);
		console.log('dataToSet', dataToSet);
		setDataToContractForm(dataToSet, formHook);
	}, [dataContract, formHook]);

	React.useEffect(() => {
		const actualDateEnd = useExtactEndDate ? exactDateEnd : dateEnd;
		if(!dateBeg || !actualDateEnd) return;
		const monthCount = dayjs(actualDateEnd).add(1, 'day').diff(dayjs(dateBeg), 'month');
		formHook.setValue('creditTermsInfo', monthCount);
	}, [dateBeg, dateEnd, exactDateEnd, useExtactEndDate]);

	const onMonthsChange = () => {
		if(creditTermsInfo){
			const dateEnd = dayjs(dateBeg)
				.add( creditTermsInfo || 0, 'months')
				.subtract(1, 'day')
				.toDate();
			formHook.setValue('dateEnd', dateEnd);
			formHook.setValue('exactDateEnd', dateEnd);
		}
	}

	const searchSuccessCallback = (contragent: ContragentFragment, contragentNumber: number) => {
		update(contragentNumber, formatPersonDataToDataForm(contragent));
	}

	const downloadContractHandler = (isn: number) => {
		downloadDoc({
			variables: {
				isn,
				type: PrintTypeEnum.Agreement,
			},
		})
			.then(({ data }) => {
				if (!data?.print) return setNotification({ type: 'error', text: 'no doc' });
				fileDownloader(data.print.url, data.print.filename);
			})
			.catch(error => setNotification({ type: 'error', text: error }))
	}

	const signContractHandler = () => {
		if (!contractId) return setNotification({ type: 'error', text: 'no contract id' });
		signContract({
			variables: {
				id: contractId
			}
		}).then(({data}) => {
			console.log('sign ok');
		}).catch(error => setNotification({ type: 'error', text: error }));
	};

	const unsignContractHandler = () => {
		if (!contractId) return setNotification({ type: 'error', text: 'no contract id' });
		unsignContract({
			variables: {
				id: contractId
			}
		}).then(({data}) => {
			console.log('unsign ok');
		}).catch(error => setNotification({ type: 'error', text: error }));
	}

	const agreementToDraftHandler = () => {
		if (!contractId) return setNotification({ type: 'error', text: 'no contract id' });
		agreementToDraft({
			variables: {
				id: contractId
			}
		}).then(({data}) => {
			if(!data?.agreementToDraft) return setNotification({ type: 'error', text: 'no agreement to draft' });
			navigate(createAgreementStep(String(data.agreementToDraft), 'contragentStep'));

		}).catch(error => setNotification({ type: 'error', text: error }));
	}

	const goToInvoiceHandler = () => {
		navigate(invoiceById(String(contractId)));
	};

	const goToAgreementHandler = () => {
		if (!agreementId) return setNotification({ type: 'error', text: 'no agreement id' });
		navigate(agreementByIdSeller(String(agreementId)));
	};

	const goToPreviousContractHandler = () => {
		if (!prevAgreementIsn) return setNotification({ type: 'error', text: 'no contract id' });
		navigate(contractById(String(prevAgreementIsn)));
	};


	/* const editContractHandler = React.useCallback(
		(isGoToAgreement: boolean) => {
			if (isGoToAgreement) return navigate(createAgreementStep(String(agreementId), 'contragentStep'));
			console.log('edit contract here');
		},
		[agreementId, createAgreementStep, navigate]
	); */


	const onFormSubmit = formHook.handleSubmit(data => {
		console.log("form submit");
		console.log(data);
		const contragent = data.contragents.at(0);
		changeAgreement({
			variables: {
				id: contractId,
				input: {
					date_begin: data.dateBeg ? formatDateToStringRu(data.dateBeg) : null,
					date_end: data.useExactDateEnd && data.exactDateEnd ? formatDateToStringRu(data.exactDateEnd) : (data.dateEnd ? formatDateToStringRu(data.dateEnd) : null),
					exact_date_end: data.useExactDateEnd && data.exactDateEnd ? formatDateToStringRu(data.exactDateEnd) : null,
					date_sign: data.dateSign ? formatDateToStringRu(data.dateSign) : null,
					ins_purpose: getClearSelectValue(data.insureType),
					ins_previous: getClearSelectValue(data.prevInsuranceCompany),
					credit_months: data.creditTerms,
					credit_agreement_number: data.creditNumber,
					sale_channel: getClearSelectValue(data.salesChannel),
					credit_sum: data.summ,
					credit_rate: data.creditRate,
					credit_agreement_date: data.agreementDate ? formatDateToStringRu(data.agreementDate) : null,
					annuitet_sum: data.annuityPay,
					policyholder: contragent ? formatFormPersonDataToBackPerson(contragent) : null,
					lifeRisks: data.risksLife.map((risk, index) => (
						{
							isn: (life_risks && life_risks[index]) ? life_risks[index]!.isn : null,
							high_tariff_height: risk.heightWeight,
							high_tariff_press: risk.pressure,
							raising_factor: risk.allowance,
							discount: -Math.abs(risk.discount || 0),
							tariff: rightsHandler('lifeTotalTariff') ? risk.tariff : null,
						}
					)),
					propertyRisks: data.risksProperty.map((risk, index) => (
						{
							isn: (property_risks && property_risks[index]) ? property_risks[index]!.isn : null,
							discount: -Math.abs(risk.discount || 0),
							base_tariff: risk.basicTariff,
							raising_factor: risk.allowance,
							permanent_sum: risk.permanentSum,
							one_time_pay: risk.oneTimePay
						}
					)),
					titulRisks: data.risksTitle.map((risk, index) => (
						{
							isn: (titul_risks && titul_risks[index]) ? titul_risks[index]!.isn : null,
							titul_date_end: risk.dateEnd ? formatDateToStringRu(risk.dateEnd) : null,
							permanent_sum: risk.permanentSum,
							one_time_pay: risk.oneTimePay
						}
					))
				}
			}
		}).then(({ data }) => {
			formHook.reset({}, {keepValues: true});
		}).catch(error => setNotification({ type: 'error', text: error }));
	});

	const sberDownloadItems: IDropdownItem[] = React.useMemo(
		() => [
			{ label: 'Жизнь', onClick: () => downloadContractHandler(Number(contractIsn)) },
			{ label: 'Имущество', onClick: () => downloadContractHandler(Number(contractIsn)) },
		],
		[downloadContractHandler]
	);

	const informationItems: InformationItem[] = React.useMemo(
		() => [
			{
				title: 'Номер договора',
				description: (
					<StyledAgreementNumber>
						<Text4>{number}</Text4>
						{!(prevAgreementId && prevAgreementIsn) && (
							<Button isLoading={loadingContract} appearance='link' isDisabled={!agreementId} onClick={goToAgreementHandler}>
								Перейти к заявке
							</Button>
						)}
						{prevAgreementId && prevAgreementIsn && (
							<Button isLoading={loadingContract} appearance='link' onClick={goToPreviousContractHandler}>
								Предыдущий договор
							</Button>
						)}
					</StyledAgreementNumber>
				),
			},
			{
				title: 'Статус',
				description: (
					<LoadingString isLoading={loadingContract}>
						<Status status={status?.label} color={status?.color} />
						{isSigned && ( isPayed ? <Status status='Оплачен' color='#397e2d' /> : <Status status='Не оплачен' color='#d23630' />)}
					</LoadingString>
				),
			},
			{
				title: 'Создана',
				description: (
					<LoadingString isLoading={loadingContract}>
						<Text4>{`${user?.username || 'Не известный'} ${added}`}</Text4>
					</LoadingString>
				),
			},
			{
				title: 'Точка продаж (группа)',
				description: (
					<LoadingString isLoading={loadingContract}>
						<Text4>{`${user?.isSuper ? 'Администратор' : `${user?.group?.name || 'неизвестно'}`}`}</Text4>
					</LoadingString>
				),
			},
		],
		[
			added,
			agreementId,
			goToAgreementHandler,
			goToPreviousContractHandler,
			isSigned,
			loadingContract,
			number,
			status?.color,
			status?.label,
			user?.group?.name,
			user?.isSuper,
			user?.username,
		]
	);

	const totalInformationItems: InformationItem[] = React.useMemo(
		() => [
			{
				title: 'Итого',
				description: (
					<LoadingString isLoading={loadingContract}>
						<Text4>{stringToMoney(premium_sum)} RUB</Text4>
					</LoadingString>
				),
			},
			{
				title: `Со скидкой КВ (${seller_discount_due_kv || 0}%)`,
				description: (
					<LoadingString isLoading={loadingContract}>
						<Text4>{stringToMoney(premium_sum_with_kv)} RUB</Text4>
					</LoadingString>
				),
			},
			...(isShowKv
				? [
					{
						title: 'Вы заработаете',
						description: (
							<LoadingString isLoading={loadingContract}>
								<StyledImportantNumber>
									<Text4>{stringToMoney(agent_kv_sum)} RUB</Text4>
								</StyledImportantNumber>
							</LoadingString>
						),
					},
				]
				: []),
		],
		[discount_kv, isShowKv, loadingContract, premium_sum, premium_sum_with_kv]
	);
	const risksInformationItems: InformationItem[] = React.useMemo(
		() => [
			{
				title: 'Людей',
				description: <Text4>{(life_risks || []).length}</Text4>,
			},
			{
				title: 'Объектов имущества',
				description: <Text4>{(property_risks || []).length}</Text4>,
			},
			{
				title: 'Титулов',
				description: <Text4>{(titul_risks || []).length}</Text4>,
			},
		],
		[life_risks, property_risks, titul_risks]
	);

	const formPart1: IForm = React.useMemo(
		() => ({
			formHook,
			fields: [
				{
					field: {
						fieldType: 'input',
						fieldName: 'contractNumber',
						isRequired: true,
						isDisabled: true,
						errorMessage: 'Введите номер',
						placeholder: 'Номер договора',
						grid: 6,
					},
				},
				{
					field: {
						fieldType: 'date',
						fieldName: 'dateSign',
						isRequired: true,
						isDisabled: !isEditable,
						errorMessage: 'Выберите дату',
						placeholder: 'Договор подписан',
						grid: 6,
					},
				},
				{
					field: {
						fieldType: 'daData',
						fieldName: 'borrower',
						daDataType: 'fio',
						isRequired: true,
						isDisabled: true,
						errorMessage: 'Введите имя',
						validationType: 'fullName',
						placeholder: 'Страхователь',
						grid: 6,
					},
				},
				{
					field: {
						fieldType: 'date',
						fieldName: 'dateBeg',
						isRequired: true,
						isDisabled: !isEditable,
						errorMessage: 'Выберите дату',
						placeholder: 'Дата начала действия договора',
						validationType: 'minMaxDate',
						minDate: dayjs().toDate(),
						maxDate: dayjs().add(31, 'years').toDate(),
						grid: 6
					},
				},
				{
					field: {
						fieldType: 'number',
						fieldName: 'creditTermsInfo',
						isRequired: true,
						isDisabled: !isEditable,
						errorMessage: 'Введите срок',
						placeholder: 'Общий срок страхования, месяцев',
						validationType: 'minMax',
						minValue: 1,
						maxValue: 1200,
						grid: 6,
						onBlur: onMonthsChange
					},
				},
				{
					isHidden: useExtactEndDate,
					field: {
						fieldType: 'date',
						fieldName: 'dateEnd',
						isRequired: true,
						isDisabled: !isEditable,
						errorMessage: 'Выберите дату',
						placeholder: 'Дата конца действия договора',
						grid: 6
					},
				},
				{
					isHidden: !useExtactEndDate,
					field: {
						fieldType: 'date',
						fieldName: 'exactDateEnd',
						isRequired: true,
						isDisabled: !isEditable,
						errorMessage: 'Выберите дату',
						placeholder: 'Точная дата окончания',
						grid: 6
					},
				},
				{
					field: {
						fieldType: 'input',
						fieldName: 'curIsn',
						isRequired: true,
						isDisabled: true,
						errorMessage: 'Введите валюту',
						placeholder: 'Валюта условий',
						grid: 6,
					},
				},
				{
					isHidden: isHiddenAnnuityPay,
					field: {
						fieldType: 'number',
						fieldName: 'annuityPay',
						digitsAfterDot: 2,
						isDisabled: !isEditable,
						placeholder: 'Аннуитетный платеж',
						grid: 6,
					},
				},
				{
					field: {
						fieldType: 'checkbox',
						fieldName: 'useExactDateEnd',
						label: 'Использовать точную дату окончания',
						isDisabled: !isEditable,
						grid: 6,
					}
				},
			],
		}),
		[formHook, isHiddenAnnuityPay, isSigned, useExtactEndDate, isEditable, creditTermsInfo, dateBeg, dateEnd, exactDateEnd]
	);

	const formPart2: IForm = React.useMemo(
		() => ({
			formHook,
			fields: [
				{
					field: {
						fieldType: 'select',
						fieldName: 'insureType',
						isRequired: true,
						isDisabled: !isEditable,
						errorMessage: 'Выберите тип договора',
						items: selectAgreementTypes,
						isLoading: loadingAgreementTypes,
						placeholder: 'Тип договора',
						grid: 6,
					},
				},
				{
					isHidden: !isChangeInsuraceCase,
					field: {
						fieldType: 'select',
						fieldName: 'prevInsuranceCompany',
						isRequired: true,
						isDisabled: !isEditable,
						placeholder: 'Предыдущая СК',
						items: selectPrevSk,
						isLoading: loadingPrevSk,
						isSearchAvailable: true,
						isClearable: true,
						grid: 6,
					},
				},
				{
					field: {
						fieldType: 'input',
						fieldName: 'creditOrganizationName',
						isRequired: true,
						errorMessage: 'Выберите кредитную организацию',
						isDisabled: true,
						placeholder: 'Наименование кредитной организации',
						grid: 6,
					},
				},
				{
					field: {
						fieldType: 'number',
						fieldName: 'summ',
						isRequired: true,
						isDisabled: !isEditable,
						digitsAfterDot: 2,
						errorMessage: 'Введите размер кредита',
						placeholder: 'Размер кредита',
						validationType: 'minMax',
						maxValue: 1000000000,
						grid: 6,
					},
				},

				{
					isHidden: isSberbank,
					field: {
						fieldType: 'number',
						fieldName: 'creditTerms',
						isRequired: true,
						isDisabled: !isEditable,
						errorMessage: '',
						validationType: 'minMax',
						maxValue: 600,
						placeholder: 'Срок кредитования (месяцы) / оставшийся срок кредитования',
						grid: 6,
					},
				},
				{
					isHidden: isSberbank,
					field: {
						fieldType: 'number',
						fieldName: 'creditRate',
						digitsAfterDot: 2,
						isRequired: true,
						isDisabled: !isEditable,
						errorMessage: '',
						placeholder: 'Годовая процентная ставка по кредиту, %',
						validationType: 'minMax',
						maxValue: 100,
						grid: 6,
					},
				},

				{
					// isHidden: isSberbank || !isChangeInsuraceCase,
					isHidden: true,
					field: {
						fieldType: 'date',
						fieldName: 'creditIssueDate',
						isDisabled: true,
						errorMessage: '',
						placeholder: 'Дата выдачи кредита',
						validationType: 'minMaxDate',
						minDate: dayjs().toDate(),
						maxDate: dayjs().add(31, 'years').toDate(),
						grid: 6,
					},
				},
				{
					// isHidden: isSberbank,
					isHidden: true,
					field: {
						fieldType: 'date',
						fieldName: 'firstPayDate',
						isDisabled: true,
						errorMessage: '',
						placeholder: 'Дата первого платежа (дата списания аннуитета)',
						grid: 6,
					},
				},

				{
					field: {
						fieldType: 'input',
						fieldName: 'creditNumber',
						isRequired: true,
						isDisabled: !isEditable,
						errorMessage: 'Это обязательное поле для договора - банк идентифицирует клиента по номеру КД',
						placeholder: 'Номер кредитного договора',
						grid: 6,
					},
				},
				{
					field: {
						fieldType: 'date',
						fieldName: 'agreementDate',
						isRequired: true,
						isDisabled: !isEditable,
						errorMessage: '',
						placeholder: 'Дата кредитного договора / договора займа',
						grid: 6,
					},
				},

				{
					field: {
						fieldType: 'select',
						fieldName: 'salesChannel',
						placeholder: 'Канал продаж',
						isRequired: true,
						isDisabled: !isSuper || !isEditable,
						errorMessage: '',
						items: selectSalesChannel,
						isLoading: loadingSalesChannel,
						grid: 6,
					},
				},
			],
		}),
		[
			formHook,
			isChangeInsuraceCase,
			isSberbank,
			isSigned,
			isSuper,
			loadingAgreementTypes,
			loadingPrevSk,
			loadingSalesChannel,
			selectAgreementTypes,
			selectPrevSk,
			selectSalesChannel,
		]
	);

	const participantsTable: any = agr_role ? {
		head: {
			cells: [
				{
					key: 'role',
					content: 'Роль',
				},
				{
					key: 'participant',
					content: 'Контрагент',
				},
				{
					key: 'date_beg',
					content: 'Начало',
				},
				{
					key: 'date_end',
					content: 'Окончание',
				}
			],
		},
		rows: agr_role.map((agrRole, index) => {
			if(!agrRole) return;
			return {
				key: `row-${index}`,
				cells: [
					{ key: `role-${index}`, content: agrRole.role },
					{ key: `participant-${index}`, content: agrRole.participant },
					{ key: `date_beg-${index}`, content: agrRole.date_beg },
					{ key: `date_end-${index}`, content: agrRole.date_end },
				]
			}
		}),
		isLoading: loadingContract
	} : [];

	const contragents = fieldsContragents.map((field, index) => ({
		isShowShare: false,
		isShowSearchByIsn: false,
		arrayName: 'contragents',
		defaultValues: field,
		number: index,
		searchSuccessCallback,
		maxAge: contragentMaxAge,
		contragentDisabled: !isEditable,
		id,
	}));

	const canChangeRisks = isProlongation ? rightsHandler('prolongationRiskChange') : rightsHandler('agreementRiskChange');

	const risksLife = fieldsRisksLife.map((field, index) => ({
		arrayName: 'risksLife',
		defaultValues: field,
		number: index,
		isSigned,
		isDisabled: !canChangeRisks || !isEditable,
	}));

	const risksProperty = fieldsRisksProperty.map((field, index) => ({
		arrayName: 'risksProperty',
		defaultValues: field,
		number: index,
		isSigned,
		isDisabled: !canChangeRisks || !isEditable
	}));

	const risksTitle = fieldsRisksTitle.map((field, index) => ({
		arrayName: 'risksTitle',
		defaultValues: field,
		number: index,
		isDisabled: !isEditable,
	}));

	const {
		user: {
			restrictions: {
				maxDiscount
			}
		},
	} = useGlobalContext();

	const maxDiscountValue = maxDiscount || 0;

	return (
		<MainLayoutIgniter>
			<Heading2>Договор страхования</Heading2>
			<StyledContractWrapper>
				<FormProvider {...formHook}>
					<form onSubmit={(event) => { console.log('submito'); return onFormSubmit(event) }}>
					<StyledHeaderWrapper>
						<StyledContractHeader>
							<Heading3>Общие сведения</Heading3>
							<StyledEventsButtons>
								{windowWidth < 768 && <DropdownMenu trigger={<Button icon={<MoreIcon />} appearance='flag' />} items={headerButtons} />}
								{windowWidth >= 768 &&
									headerButtons.map(({ label, event, icon, isDisabled }, index) => (
										<Button key={`headerButton-${index}`} tooltip={label} appearance='icon' icon={icon} isLoading={loadingContract} isDisabled={isDisabled} onClick={event} />
									))}
							{!isSigned &&
								<Button
									tooltip={isDisabledWithoutRecalc ? 'Требуется перерасчет' : ''}
									isLoading={isLoading || loadingDownloadDoc}
									appearance='link'
									iconBefore={<DownloadIcon />}
									onClick={() => downloadContractHandler(Number(contractIsn))}
								>
									Скачать проект договора
								</Button>
							}
							{isSigned && (
								<FlyButtons isWhiteBackground={true}>
									<StyledSignetAgreementButtons>
										{risksProperty.length > 0 && !has_cross_sale &&
											<Button isLoading={isLoading} isDisabled={isCanceled} appearance='transparent' onClick={() => setStateCrossModal(true)}>
												Защита ипотеки
											</Button>
										}
										{isSigned && (
											<Button isLoading={isLoading} isDisabled={isDisabledGoToInvoice} appearance='transparent' onClick={goToInvoiceHandler}>
												Получить документы
											</Button>
										)}
										{needProlongation && (
											<Button appearance='filled' isLoading={isLoading} onClick={() => setProlongationModalIsOpen(true)}>
												Продлить договор
											</Button>
										)}
										{nextAgreementIsn &&
											<Button appearance='transparent' isLoading={isLoading} onClick={() => navigate(contractById(String(nextAgreementIsn)))}>
												Договор продлен: перейти
											</Button>
										}
										{!needProlongation && nextInvoiceAllowed && !isCurrentPeriodPayed && (
											<Button appearance='filled' isLoading={isLoading} onClick={() => setPayPeriodModalIsOpen(true)}>
												Оплата очередного взноса
											</Button>
										)}
										{/*{!needProlongation && nextInvoiceAllowed && (
											<DropdownMenu
												trigger={
													<Button iconAfter={<SelectArrowIcon direction='down' />} appearance='filled'>
														Внести изменения
													</Button>
												}
												direction='down'
												items={[
													{label: "Дострахование рисков", onClick: () => { setChangeAddModalIsOpen(true) }},
													...(life_risks && life_risks.length && property_risks && property_risks.length || property_risks && property_risks.length && titul_risks && titul_risks.length) ?
													[ {label: "Исключение рисков", onClick: () => { setChangeRemoveModalIsOpen(true) }} ] : [],
													{label: "Перерасчет", onClick: () => { setChangeRecalcModalIsOpen(true) }},
												]}
											/>

										)}*/}

										{!isPayed && (
											<Button appearance='filled' isLoading={loadingUnsignContract} onClick={() => unsignContractHandler()} tooltip='Редактировать договор, применить скидку'>
												Вернуть в Оформление
											</Button>
										)}

										{/*<DropdownMenu
											trigger={
												<Button iconAfter={<SelectArrowIcon direction='down' />} appearance='filled' isLoading={loadingUnsignContract || loadingAgreementToDraft}>
													Внести изменения
												</Button>
											}
											direction='down'
											items={[
												{label: "С изменением условий", onClick: () => { agreementToDraftHandler() }},
												{label: "Без изменения условий", onClick: () => { unsignContractHandler() }},
											]}
										/>*/}

									</StyledSignetAgreementButtons>
								</FlyButtons>
							)}
							</StyledEventsButtons>

							{!isSigned && (
								<FlyButtons isWhiteBackground={true}>
									<StyledButtonsGroupAutoSize>
										<Button isLoading={isLoading} appearance='transparent' type='submit' isDisabled={isCanceled}>
											Пересчитать премию
										</Button>
										{isProlongation && rightsHandler('prolongationKvDiscount') &&
											<Button
												isLoading={isLoading}
												appearance='filled'
												onClick={() => setDiscountModalIsOpen(true)}
											>
												Скидка за счет КВ
											</Button>
										}
										{isDeclarationWay && !agreementDeclarationDone &&
											<Button
												isLoading={isLoading}
												appearance='filled'
												onClick={() => {
													setStateIsDeclarationWay(true);
													setDeclModalIsOpen(true)
												}}
											>
												Декларация
											</Button>
										}
										{agreementAnketaIsn && !agreementDeclarationDone &&
											<Button
												isLoading={isLoading}
												appearance='filled'
												onClick={() => {
													if(agreementAnketaEdit){
														navigate(contractAnketaById(String(contractId)));
													} else {
														setStateIsDeclarationWay(false);
														setDeclModalIsOpen(true);
													}
												}}
											>
												Анкета
											</Button>
										}
										{isDeclarationWayWithAnketa && !agreementAnketaIsn &&
											<Button
												isLoading={isLoading}
												appearance='filled'
												onClick={() => setDeclAnkModalIsOpen(true)}
											>
												Анкета найдена
											</Button>
										}
										{(!(isDeclarationWay || isDeclarationWayWithAnketa) || agreementDeclarationDone || isAllowedToSkipDeclaration) &&
											<Button
												isLoading={isLoading}
												appearance='filled'
												isDisabled={isDisabledWithoutRecalc}
												onClick={signContractHandler}
												tooltip={isDisabledWithoutRecalc && !isCanceled ? 'Требуется перерасчет' : ''}
											>
												Подписать договор
											</Button>
										}

									</StyledButtonsGroupAutoSize>
								</FlyButtons>
							)}
						</StyledContractHeader>
						<StyledHeaderInformation>
							{informationItems.map(({ title, description }, index) => (
								<StyledInformationItem key={`info-item-${index}`}>
									<Text4>{title}</Text4>
									{description}
								</StyledInformationItem>
							))}
							<StyledTotalInformation>
								{totalInformationItems.map(({ title, description }, index) => (
									<StyledTotalInformationItem key={`info-item-${index}`}>
										<Text4>{title}</Text4>
										{description}
									</StyledTotalInformationItem>
								))}
							</StyledTotalInformation>
						</StyledHeaderInformation>
					</StyledHeaderWrapper>

					<StyledContractRegistrationForm>
						<StyledRisksInformation>
							{risksInformationItems.map(({ title, description }, index) => (
								<StyledInformationItem key={`risk-item-${index}`}>
									<Text4>{title}</Text4>
									{description}
								</StyledInformationItem>
							))}
						</StyledRisksInformation>
						<FieldsBuilder {...formPart1} />
						<Heading3>Общие сведения о страхователе</Heading3>
						{contragents.map(contragent => (
							<ContragentIgniter key={`contragent-${contragent.number}`} {...contragent} />
						))}
						<Heading3>Основные положения кредитного договора</Heading3>
						<FieldsBuilder {...formPart2} />

						{risksLife.length > 0 && (
							<>
								<Heading3>Страхование жизни, потери трудоспособности залогодателя</Heading3>
								{risksLife.map(risk => (
									<React.Fragment key={`risk-life-${risk.number}`}>
										<Heading4>Заемщик {risk.number + 1}</Heading4>
										<RiskLifeIgniter {...risk} maxDiscount={maxDiscountValue} />
									</React.Fragment>
								))}
							</>
						)}

						{risksProperty.length > 0 && (
							<>
								<Heading3>Страхование имущества от гибели,утраты, повреждения</Heading3>
								{risksProperty.map(risk => (
									<React.Fragment key={`risk-property-${risk.number}`}>
										<Heading4>Объект страхования {risk.number + 1}</Heading4>
										<RiskPropertyIgniter {...risk} maxDiscount={maxDiscountValue} />
									</React.Fragment>
								))}
							</>
						)}

						{risksTitle.length > 0 && (
							<>
								<Heading3>Страхование потери имущества в результате прекращения, ограничения (обременения) права собственности</Heading3>
								{risksTitle.map(risk => (
									<React.Fragment key={`risk-title-${risk.number}`}>
										<Heading4>Объект страхования {risk.number + 1}</Heading4>
										<RiskTitleIgniter {...risk} />
									</React.Fragment>
								))}
							</>
						)}
					</StyledContractRegistrationForm>
				</form>
				</FormProvider>

				{agr_role && agr_role.length > 0 &&
					<StyledTableAreaWrapper>
						<Heading3>Участники договора</Heading3>
						<Table head={participantsTable.head} rows={participantsTable.rows} isLoading={participantsTable.isLoading}/>
					</StyledTableAreaWrapper>
				}

				<StyledContractDocuments>
					<DocumentsWidget id={contractId} isOpenDefault={true} resultDocuments={[]} />
				</StyledContractDocuments>
			</StyledContractWrapper>
			<ProlongationModal onClose={() => {setProlongationModalIsOpen(false)}} isOpen={stateProlongationModalIsOpen} data={{contractIsn}}/>
			<PayPeriodModal onClose={() => { setPayPeriodModalIsOpen(false) }} isOpen={statePayPeriodModalIsOpen} data={{
				sum: nextInvoiceSum,
				number,
				email: agreement?.policyholder?.email,
				invoice: (invoices || [])[0]
			}} />
			<DeclarationModal
				onClose={() => setDeclModalIsOpen(false)}
				isOpen={stateDeclModalIsOpen}
				agreementId={contractId}
				personId={agreement?.policyholder?.isn}
				phone={agreement?.policyholder?.phone}
				agreementMode={stateIsDeclarationWay}
				showDeclaration={stateIsDeclarationWay}
				onlyBySms={(isProlongation && rightsHandler('prolongationNeedSms')) || false}
			/>
			<AgreementAnketaModal
				onClose={() => setDeclAnkModalIsOpen(false)}
				isOpen={stateDeclAnkModalIsOpen}
				data={{
					anketaIsn: prevAgreementAnketaIsn,
					setDeclModalIsOpen,
				}}
			/>
			<ChangeAddModal
				onClose={() => setChangeAddModalIsOpen(false)}
				isOpen={stateChangeAddModalIsOpen}
				data={{
					life_risks: !!life_risks?.length,
					property_risks: !!property_risks?.length,
					titul_risks: !!titul_risks?.length
				}}
			/>
			<ChangeRemoveModal
				onClose={() => setChangeRemoveModalIsOpen(false)}
				isOpen={stateChangeRemoveModalIsOpen}
				data={{
					life_risks,
					property_risks,
					titul_risks
				}}
			/>
			<ChangeRecalcModal onClose={() => setChangeRecalcModalIsOpen(false)} isOpen={stateChangeRecalcModalIsOpen} />
			<CrossModal
				onClose={() => setStateCrossModal(false)}
				isOpen={stateCrossModal}
				data={{
					dateBegin: agreement?.date_begin
			  }}
			/>
			<DiscountIgniter id={contractId} discount={0} isOpen={stateDiscountModalIsOpen} onClose={() => setDiscountModalIsOpen(false)} />
			<AgreementChangeLogIgniter id={contractId} onClose={() => setChangeLogModalIsOpen(false)} isOpen={stateChangeLogModalIsOpen} />
		</MainLayoutIgniter>
	);
};

export default React.memo(ContractPage);
