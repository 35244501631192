import React from 'react';
import { useCreateAgreementContext } from './context';
import MainLayoutIgniter from '../../common/mainLayout';
import { StyledPaginationButtonWrapper, StyledStepFormHeader, StyledStepFormWrapper, StyledStepsHeader, StyledStepWrapper } from './styles';
import { Heading2, Heading3, Text4 } from '../../../../libs/uiKit/globalStyledComponents';
import { ArrowIcon } from '../../../../icons/ui/Arrow';
import Button from '../../../../libs/uiKit/button';
import DeclarationModal from "../widgets/declarationModal";
import FinalWarningModal from "../widgets/FinalWarningModal";

const CreateAgreementUi: React.FC<{ children: React.ReactNode }> = props => {
	const { children } = props;
	const {
		isEdit,
		goBackHandler,
		currentStepNumber,
		currentStepTitle,
		isShowNextStep,
		isShowPrevStep,
		isDisabledPrevStep,
		isDisabledNextStep,
		prevStepHandler,
		onFormSubmit,
		isLoading,
		isLastStep,
		closeCreateModalHandler,
		closeDeclarationModalHandler,
		createModalIsOpen,
		declarationModalIsOpen,
		agreementId,
		calcForm,
		isDeclarationAvailable,
		createAgreementAvaiable,
		expressMode,
		addendumMode,
	} = useCreateAgreementContext();

	return (
		<MainLayoutIgniter>
			<form onSubmit={onFormSubmit}>
				<StyledStepWrapper>
					<StyledStepsHeader>
						{!isEdit && (
							<Button onClick={goBackHandler} appearance='text' iconBefore={<ArrowIcon direction='left' />}>
								Предрасчет
							</Button>
						)}
						<Heading2>{addendumMode ? 'Дострахование рисков' : (isEdit ? 'Изменение заявки на страхование' : 'Создание заявки на страхование')}</Heading2>
					</StyledStepsHeader>

					<StyledStepFormWrapper>
						<StyledStepFormHeader>
							<Heading3>{currentStepTitle}</Heading3>
							<Text4>Шаг {currentStepNumber}</Text4>
						</StyledStepFormHeader>
						{children}
					</StyledStepFormWrapper>

					<StyledPaginationButtonWrapper>
						{isShowPrevStep && (
							<Button isDisabled={isDisabledPrevStep} appearance='transparent' isLoading={isLoading} onClick={prevStepHandler}>
								Предыдущий шаг
							</Button>
						)}
						{!isShowPrevStep && <div/>}
						{isShowNextStep && (
							<Button isDisabled={isDisabledNextStep} type='submit' appearance='filled' isLoading={isLoading}>
								{isLastStep ? (addendumMode ? 'Дострахование' : (createAgreementAvaiable ? 'Создать договор' : 'Создать заявку')) : 'Следующий шаг'}
							</Button>
						)}
					</StyledPaginationButtonWrapper>
				</StyledStepWrapper>
				<FinalWarningModal isOpen={createModalIsOpen} onClose={closeCreateModalHandler} isLoading={isLoading} createAgreement={createAgreementAvaiable}/>
				<DeclarationModal
					onClose={closeDeclarationModalHandler}
					isOpen={declarationModalIsOpen}
					agreementId={agreementId}
					personId={calcForm?.policyholder?.isn}
					phone={calcForm?.policyholder?.phone}
					agreementMode={false}
					showDeclaration={isDeclarationAvailable}
				/>
			</form>
		</MainLayoutIgniter>
	);
};

export default React.memo(CreateAgreementUi);
