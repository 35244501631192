import React from 'react';
import { useSettingsContext } from './context';
import MainLayoutIgniter from '../../common/mainLayout';
import { Heading2 } from '../../../../libs/uiKit/globalStyledComponents';
import { FieldsBuilder } from '../../../../libs/uiKit/fieldsBuilder';
import Button from '../../../../libs/uiKit/button';
import { StyledSettingsWrapper, StyledStepFormWrapper, StyledStepWrapper } from './styles';
import ChangeLogModal from "../../roles/widgets/changeLogModal";

const SettingsUi: React.FC = () => {
	const { form, onFormSubmit, isLoading, stateIsOpenHistory, setIsOpenHistory, historyData, isDisabledEdit } = useSettingsContext();

	return (
		<MainLayoutIgniter>
			<StyledSettingsWrapper>
				<StyledStepWrapper>
					<Heading2>Настройки</Heading2>
					<form onSubmit={onFormSubmit}>
						<StyledStepFormWrapper>
							<FieldsBuilder {...form} />
						</StyledStepFormWrapper>
						<Button isLoading={isLoading} isDisabled={isDisabledEdit} type='submit' appearance='filled'>
							Сохранить
						</Button>
					</form>
				</StyledStepWrapper>
			</StyledSettingsWrapper>
			<ChangeLogModal
				onClose={() => setIsOpenHistory(false)}
				isOpen={stateIsOpenHistory}
				data={historyData}
				header='История изменения настроек'
			/>
		</MainLayoutIgniter>
	);
};

export default React.memo(SettingsUi);
