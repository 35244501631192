import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {useMutation}                   from "@apollo/react-hooks";
import useGetDicti from "../../common/hooks/useGetDicti";
import {DictKeyEnum} from "../../../../libs/api/graphqlTypes";
import {IModalProps} from "../../../../libs/uiKit/modal/types";
import useNotification from "../../../widgets/notifier/ui/hooks/useNitification";

import {IForm, IFormField} from "../../../../libs/uiKit/fieldsBuilder/types";
import {Modal} from "../../../../libs/uiKit/modal";
import {FieldsBuilder} from "../../../../libs/uiKit/fieldsBuilder";
import {StyledButtonsGroup} from "../../invoice/widgets/sendPayLinkModal/styles";
import Button from "../../../../libs/uiKit/button";
import {PROLONG, SEARCH_AGREEMENT} from "../../../../libs/api/commands";
import {stringToNumber} from "../../../../libs/uiKit/utils";
import {formatDateToStringRu} from "../../../../libs/utils/gormatDateToUsa";
import {useGlobalContext} from "../../../../apps/main/ui/GlobalContext";
import {StyledAgrInfoLine} from "../ui/styles";

const SearchProlongationModal = (props: IModalProps) => {
  const {setNotification} = useNotification();
  
  const {
    user: {
      isStaffMember
    },
    routes: { contractById },
  } = useGlobalContext();
  
  const navigate = useNavigate();

  const [searchAgreement, {loading: loadingSearchAgreement}] = useMutation(SEARCH_AGREEMENT);
  const [agreementProlongation, {loading: loadingAgreementProlongation}] = useMutation(PROLONG);
  const { data: agentsItems, loading: loadingAgents } = useGetDicti(DictKeyEnum.Agentlist);

  const formHook = useForm({mode: "onBlur"});
  const {handleSubmit, watch} = formHook;
  const [agrData, setAgrData] = useState<any>(null);

  const form: IForm = {
    formHook,
    hotReload: true,
    fields: [
      {
        isHidden: !!agrData,
        field: {
          fieldName: "number",
          fieldType: "input",
          isRequired: true,
          errorMessage: "Введите номер договора",
          placeholder: "Номер договора"
        }
      },
      {
        isHidden: !agrData,
        field: {
          fieldName: "limitSum",
          fieldType: "number",
          digitsAfterDot: 2,
          isRequired: true,
          errorMessage: "Введите страховую сумму",
          placeholder: "Страховая сумма"
        }
      },
      {
        isHidden: !agrData,
        field: {
          fieldName: "signDate",
          fieldType: "date",
          isRequired: true,
          // validationType: "minMaxDate",
          // minDate: date,
          // defaultValue: date,
          errorMessage: "Выберите дату подписания",
          placeholder: "Дата подписания"
        }
      },
      {
        isHidden: !isStaffMember || !agentsItems.length || !agrData,
        field: {
          fieldName: "agentIsn",
          fieldType: "select",
          items: agentsItems,
          isClearable: true,
          isSearchAvailable: true,
          errorMessage: "Выберите агента",
          placeholder: "Агент"
        }
      },
    ],
  };

  const onFormSubmit = handleSubmit((formData) => {
    
    const {number, limitSum, signDate, agentIsn} = formData;

    if(agrData){
      agreementProlongation({
        variables: {
          isn: String(agrData.isn),
          date_sign: formatDateToStringRu(signDate),
          agent: agentIsn,
          sum: Number(stringToNumber(limitSum))
        }
      })
        .then(({data: {prolong}}) => {
          if (!prolong) return setNotification({type: "error", text: "Отсутствует id договора"});
          props.onClose();
          navigate(contractById(String(prolong)))
        })
        .catch(error => setNotification({type: "error", text: error}))
      return;
    }

    searchAgreement({
      variables: {
        number
      }
    }).then((data) => {
      setAgrData(data?.data?.searchAgreement);
    }).catch(error => setNotification({type: "error", text: error}))
  });

  return (
    <Modal {...props} isLoading={loadingAgents} header="Продлить договор" onCloseByX={() => {setAgrData(null);}}>
      <form onSubmit={onFormSubmit}>
        <FieldsBuilder {...form}/>
        {agrData && <div>
            <StyledAgrInfoLine>
                <p>Номер договора:</p><p>{agrData.policy_no}</p>
            </StyledAgrInfoLine>
            <StyledAgrInfoLine>
                <p>Дата начала:</p><p>{agrData.date_begin}</p>
            </StyledAgrInfoLine>
            <StyledAgrInfoLine>
                <p>Дата окончания:</p><p>{agrData.date_end}</p>
            </StyledAgrInfoLine>
            <StyledAgrInfoLine>
                <p>Продукт:</p><p>{agrData.product_name}</p>
            </StyledAgrInfoLine>
            <StyledAgrInfoLine>
                <p>Имя страхователя:</p><p>{agrData.policy_holder?.lastname} {agrData.policy_holder?.firstname} {agrData.policy_holder?.parentname}</p>
            </StyledAgrInfoLine>
        </div>}

        <StyledButtonsGroup>
          {!agrData && <Button
            type="submit"
            isLoading={loadingSearchAgreement}
            appearance="filled">
            Получить договор
          </Button>}
          {!!agrData && <Button
            type="submit"
            isLoading={loadingAgreementProlongation}
            appearance="filled">
            Продлить
          </Button>}
          <Button
            onClick={() => {
              setAgrData(null);
              props.onClose()
            }}
            isLoading={false}
            appearance="transparent">
            Отмена
          </Button>
        </StyledButtonsGroup>
      </form>
    </Modal>
  )
};

export default SearchProlongationModal;