import React, { useRef } from 'react';
import { IClickAwayProps } from './types';
import { StyledClickAwayWrapper } from './styles';

const ClickAway: React.FC<IClickAwayProps> = props => {
	const { children, isOpen, onClickAway } = props;
	const refWrapper = useRef<HTMLDivElement>(null);

	const escapeListener = React.useCallback(
		(ev: KeyboardEvent): void => {
			if (ev.key !== 'Escape') return;
			onClickAway(ev);
		},
		[onClickAway]
	);

	const clickListener = React.useCallback(
		(ev: MouseEvent) => {
			if (!refWrapper.current) return;
			if (!refWrapper.current.contains(ev.target as Node)) onClickAway(ev);
		},
		[refWrapper, onClickAway]
	);

	React.useEffect(() => {
		if (isOpen) {
			document.removeEventListener('click', clickListener);
			document.removeEventListener('keyup', escapeListener);
			document.addEventListener('click', clickListener);
			document.addEventListener('keyup', escapeListener);
		}
		if (!isOpen) {
			document.removeEventListener('click', clickListener);
			document.removeEventListener('keyup', escapeListener);
		}
		return (): void => {
			document.removeEventListener('click', clickListener);
			document.removeEventListener('keyup', escapeListener);
		};
	}, [isOpen, escapeListener, clickListener]);

	return <StyledClickAwayWrapper ref={refWrapper}>{children}</StyledClickAwayWrapper>;
};

export default React.memo(ClickAway);
