import React, {createContext, useContext, useState} from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';
import useGetDicti from '../../../common/hooks/useGetDicti';
import { DictKeyEnum, PrintExpressCalculationMutation, PrintExpressCalculationMutationVariables } from '../../../../../libs/api/graphqlTypes';
import { IForm, IFormField } from '../../../../../libs/uiKit/fieldsBuilder/types';
import { ITabItem } from '../../../../../libs/uiKit/tabs/types';
import { ISelectItem } from '../../../../../libs/uiKit/select/types';
import { IEnumItem } from '../../../../../libs/uiKit/enums/types';
import useNotification from '../../../../widgets/notifier/ui/hooks/useNitification';
import { stringToMoney } from '../../../../../libs/uiKit/utils';
import { PROPERTY_TYPE, RADIO_BOOLEAN, RADIO_SEX, RADIO_TITLE, RISKS_SELECT } from '../../../../../libs/utils/staticData';
import { useGlobalContext } from '../../../../../apps/main/ui/GlobalContext';
import { useUserRights } from '../../../common/hooks/useUserRights';
import usePrecalculationCommands from '../../ds/repositories/commands';
import { DOWNLOAD_PRECALC } from '../../../../../libs/api/commands';
import { fileDownloader } from '../../../../../libs/utils/fileDownloader';
import {IDaDataValue} from "../../../../../libs/uiKit/daData/types";

type PrecalculationContext = {
	goBackHandler: () => void;
	onFormSubmit: () => void;
	currentTab: ITabItem;
	changeTabHandler: (value: ITabItem | undefined) => void;
	isShowHouseInProgress: boolean;
	isShowHouseReady: boolean;
	isShowRiskLife: boolean;
	isShowRiskProperty: boolean;
	isShowRiskTitle: boolean;
	isLoading: boolean;
	isDisabled: boolean;
	precalculationForm: IForm;
	borrowerForm: IForm;
	risksForm: IForm;
	propertyForm: IForm;
	titleForm: IForm;
	borrowersItems: BorrowerItem[];
	downloadAllHandler: () => void;
	downloadSelectedHandler: () => void;
	selectedLength: number;
	isLoadingResults: boolean;
	changeSelectedPrecalcHandler: (isn: string) => void;
	selectBankHandler: (isn: string, expressMode: boolean) => void;
	resultItems: ResultItem[];
	isDisabledSelectBank: boolean;
};

type BorrowerItem = { id: string; form: IForm };

export type PersonField = {
	birthDateMethod?: ISelectItem;
	id: string;
	birthDate?: Date;
	sex?: string;
	share?: number;
	isChronical?: string;
	riskGroup: ISelectItem;
	profession: ISelectItem;
};

type BorrowerResultItem = {
	name: string | undefined;
	tariff: number;
	premium: number;
	needOms: boolean;
};

export type RiskResultItem = {
	name: string | undefined;
	isTableForLife: boolean;
	borrowers: BorrowerResultItem[];
};

type ResultItem = {
	isSelected: boolean;
	isn: string;
	isnBank: string;
	name: string;
	sum: string;
	premium: string;
	logo: string;
	risks: RiskResultItem[];
	isExpress: boolean;
	noExpressReason?: string;
};

export type PrecalculationForm = {
	borrowers?: PersonField[];
	haveCoBorrower?: string;
	life?: boolean;
	property?: boolean;
	title?: boolean;
	objectType?: ISelectItem;
	objectBuildYear?: string;
	ownershipYears?: string;
	summ?: number;
	banks?: IEnumItem[];
	creditIssueDate: Date;
	dealCountry?: IDaDataValue | null;
	woodInConstruction?: string;
	agentIsn?: ISelectItem;
};

const PrecalculationContext = createContext<PrecalculationContext>({} as PrecalculationContext);
export const usePrecalculationContext = (): PrecalculationContext => useContext(PrecalculationContext);

const PrecalculationContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const {
		user: { isStaffMember },
		routes: { createAgreementStep },
	} = useGlobalContext();
	const navigate = useNavigate();
	const { setNotification } = useNotification();
	const { rightsHandler } = useUserRights();
	const { createAgreementCmd, isLoadingSaveAgreement, precalculationCmd, isLoadingPrecalculation, dataPrecalculation } = usePrecalculationCommands();
	const { data: selectBanks, loading: loadingBanks } = useGetDicti(DictKeyEnum.CreditBank);
	const { data: selectPledge, loading: loadingPledge } = useGetDicti(DictKeyEnum.PropertyType);
	const { data: selectRiskGroup } = useGetDicti(DictKeyEnum.RiskGroup);
	const { data: selectProfession } = useGetDicti(DictKeyEnum.Profession);
	const [stateSelectedPrecalc, setSelectedPrecalc] = React.useState<string[]>([]);
	const [stateTabs, setTab] = React.useState<ITabItem | undefined>(PROPERTY_TYPE[0]);
	const [statePrecalculationFormData, setPrecalculationFormData] = React.useState<PrecalculationForm | null>(null);

	const { data: agentsItems, loading: loadingAgents } = useGetDicti(DictKeyEnum.Agentlist);

	const [downloadPrecalc, { loading: loadingDownloadPrecalc }] = useMutation<PrintExpressCalculationMutation, PrintExpressCalculationMutationVariables>(DOWNLOAD_PRECALC);

	const formHook = useForm<PrecalculationForm>({
		mode: 'onBlur',
		defaultValues: {
			haveCoBorrower: String(RADIO_BOOLEAN[1].value),
		},
	});
	const { fields, append, remove } = useFieldArray({
		control: formHook.control,
		name: 'borrowers',
	});

	const [banks, riskLife, riskProperty, riskTitle, borrowers] = formHook.watch(['banks','life', 'property', 'title', 'borrowers']);
	const [riskGroup0State, setRiskGroup0State] = useState<boolean>(false);
	const [riskGroup1State, setRiskGroup1State] = useState<boolean>(false);
	const [profession1State, setProfession1State] = useState<boolean>(false);
	const [profession0State, setProfession0State] = useState<boolean>(false);

	let banksWithRaising:IFormField[] = [];

	if(banks && banks.length){
		const selectedBanksIsn = banks.map((bank) => bank.value);
		banksWithRaising = selectBanks
			.filter((bank) => selectedBanksIsn.includes(bank.value) && bank.extra.raising > 1)
			.map((bank) => ({
				field: {
					fieldType: 'number',
					fieldName: `rate-${bank.value}`,
					digitsAfterDot: 2,
					placeholder: `Процентная ставка банка ${bank.label}`,
					validationType: 'minMax',
					minValue: 0,
					maxValue: 100,
					isRequired: true,
					errorMessage: 'Введите процентную ставку',
				},
			}))
	}

	const isDisabledPrecalculation: boolean = !(riskLife || riskProperty || riskTitle) && stateTabs?.value === '1';
	const resultItems: ResultItem[] = (dataPrecalculation?.precalc || []).reduce<ResultItem[]>((prev, item) => {
		if (!item || !item.bank || !item.isn) return prev;
		return [
			...prev,
			{
				isSelected: stateSelectedPrecalc.includes(item.isn),
				isn: item.isn,
				isnBank: item.bank.isn,
				name: item.bank.name,
				sum: `${stringToMoney(item.sum)} RUB`,
				premium: `${stringToMoney(item.total)} RUB`,
				logo: `${window.location.origin}${item.bank.icon}`,
				risks: (item.risks || []).map(risk => ({
					name: risk?.name || undefined,
					isTableForLife: risk?.name === 'Жизнь',
					borrowers: (risk?.borrowers || []).map((borrower, index) => ({
						name: borrower?.name || undefined,
						tariff: borrower?.tariff || 0,
						premium: borrower?.premium_sum || 0,
						needOms: !!borrower?.need_medical_checkup || (((borrowers||[])[index]||{})?.isChronical === "true"),
					})),
				})),
				isExpress: !!item.isExpressDeclarationAvailable,
				noExpressReason: item.noExpressDeclarationReason || "",
			},
		];
	}, []);

	const downloadAllHandler = React.useCallback(() => {
		if (!resultItems.length) return;
		downloadPrecalc({
			variables: {
				isn: resultItems.map(({ isn }) => isn),
			},
		})
			.then(({ data }) => {
				if (!data?.printExpressCalculation) return setNotification({ type: 'error', text: 'no doc' });
				fileDownloader(data.printExpressCalculation.url, data.printExpressCalculation.filename);
			})
			.catch(error => setNotification({ type: 'error', text: error }));
	}, [downloadPrecalc, resultItems, setNotification]);

	const downloadSelectedHandler = React.useCallback(() => {
		if (!stateSelectedPrecalc.length) return;
		downloadPrecalc({
			variables: {
				isn: stateSelectedPrecalc,
			},
		})
			.then(({ data }) => {
				if (!data?.printExpressCalculation) return setNotification({ type: 'error', text: 'no doc' });
				fileDownloader(data.printExpressCalculation.url, data.printExpressCalculation.filename);
			})
			.catch(error => setNotification({ type: 'error', text: error }));
	}, [downloadPrecalc, setNotification, stateSelectedPrecalc]);

	const changeSelectedPrecalcHandler = React.useCallback(
		(currentIsn: string) => {
			if (stateSelectedPrecalc.includes(currentIsn)) return setSelectedPrecalc(state => state.filter(isn => isn !== currentIsn));
			setSelectedPrecalc(state => [...state, currentIsn]);
		},
		[stateSelectedPrecalc]
	);

	const selectBankHandler = React.useCallback(
		(bankIsn: string, expressMode: boolean) => {
			if (!statePrecalculationFormData) return setNotification({ type: 'error', text: 'no form data' });
			createAgreementCmd({
				bank: selectBanks.find(bank => bank.value === bankIsn),
				tabValue: stateTabs,
				formData: statePrecalculationFormData,
				callback: isn => navigate(createAgreementStep(isn, 'contragentStep')),
				expressMode,
			});
		},
		[createAgreementCmd, createAgreementStep, navigate, selectBanks, setNotification, statePrecalculationFormData, stateTabs]
	);

	const changeHaveCoBorrowerHandler = React.useCallback(
		(ev: React.ChangeEvent<HTMLInputElement>) => {
			if (ev.target.value === 'true') append({ share: 0 });
			if (ev.target.value === 'false') remove(1);
		},
		[append, remove]
	);

	const changeRisksHandler = React.useCallback(
		(checked: boolean, risk: string | number): void => {
			if (!checked && risk === 'property') formHook.setValue('title', false);
		},
		[formHook]
	);

	const onFormSubmit = formHook.handleSubmit(data => {
		precalculationCmd({
			formData: data,
			tabValue: stateTabs,
			callback: () => {
				setPrecalculationFormData(data);
				setSelectedPrecalc([]);
				window.scrollTo({
					left: 0,
					top: 0,
					behavior: "smooth"
				});
			},
		});
	});

	const onChangeRiskGroup = (index: number, ev: ISelectItem | null) => {
		const riskGroupSelected: boolean = !!ev;
		if(index > 0){
			setRiskGroup1State(riskGroupSelected);
		} else {
			setRiskGroup0State(riskGroupSelected);
		}
	}

	const onChangeProfession = (index: number, ev: ISelectItem | null) => {
		const professionSelected: boolean = !!ev;
		if(index > 0){
			setProfession1State(professionSelected);
		} else {
			setProfession0State(professionSelected);
		}
	}

	React.useEffect(() => {
		remove();
		append({ share: 100 });
		formHook.setValue('haveCoBorrower', RADIO_BOOLEAN[1].value);
	}, [append, formHook, remove, stateTabs?.value]);

	const borrowersItems: BorrowerItem[] = fields.map(({ id }, index) => {
		const defaultValues: PersonField = fields[index];
		const personLabel = (index === 0) ? 'заемщика' : 'созаемщика';
		return {
			id,
			form: {
				formHook,
				fields: [
					{
						field: {
							fieldType: 'date',
							fieldName: `borrowers[${index}].birthDate`,
							isRequired: true,
							placeholder: `Дата рождения ${personLabel}`,
							errorMessage: 'Выберите дату рождения',
							defaultValue: defaultValues.birthDate,
						},
					},
					{
						isHidden: !rightsHandler('unifiedRatesCalculation'),
						field: {
							fieldType: 'select',
							fieldName: `borrowers[${index}].birthDateMethod`,
							placeholder: `Расчет возраста ${personLabel}`,
							defaultValue: {label: "Автоматически", value: "auto"},
							items: [
								{label: "Автоматически", value: "auto"},
								{label: "По году рождения", value: "0"},
								{label: "По дате рождения", value: "1"},
							]
						},
					},
					{
						label: `Пол ${personLabel}`,
						field: {
							fieldType: 'radio',
							fieldName: `borrowers[${index}].sex`,
							defaultValue: defaultValues.sex || RADIO_SEX[0].value,
							items: RADIO_SEX,
						},
					},
					{
						field: {
							fieldType: 'range',
							fieldName: `borrowers[${index}].share`,
							isRequired: true,
							placeholder: `Доля ${personLabel} в общем доходе, %`,
							defaultValue: defaultValues.share,
							min: 0,
							max: 100,
							step: 1,
							errorMessage: `Выберите долю ${personLabel}`,
						},
					},
					{
						label: 'Наличие хронических заболеваний',
						field: {
							fieldType: 'radio',
							fieldName: `borrowers[${index}].isChronical`,
							defaultValue: defaultValues.isChronical || RADIO_BOOLEAN[1].value,
							items: RADIO_BOOLEAN,
						},
					},
					{
						field: {
							fieldType: 'select',
							fieldName: `borrowers[${index}].riskGroup`,
							placeholder: `Группа риска ${personLabel}`,
							errorMessage: 'Выберите группу риска',
							items: selectRiskGroup,
							isClearable: true,
							isDisabled: index > 0 ? profession1State : profession0State,
							onChange: (ev) => onChangeRiskGroup(index, ev)
						},
					},
					{
						field: {
							fieldType: 'select',
							fieldName: `borrowers[${index}].profession`,
							placeholder: `Профессия ${personLabel}`,
							errorMessage: 'Выберите профессию',
							items: selectProfession,
							isSearchAvailable: true,
							isClearable: true,
							isDisabled: index > 0 ? riskGroup1State : riskGroup0State,
							onChange: (ev) => onChangeProfession(index, ev)
						},
					},
				],
			},
		};
	});

	const propertyForm: IForm = React.useMemo(
		() => ({
			formHook,
			fields: [
				{
					field: {
						fieldType: 'select',
						fieldName: 'objectType',
						isRequired: true,
						placeholder: 'Тип объекта недвижимости',
						isLoading: loadingPledge,
						items: selectPledge,
						defaultValue: selectPledge[0],
						errorMessage: 'Выберите тип объекта недвижимости',
					},
				},
				{
					field: {
						fieldType: 'inputMask',
						fieldName: 'objectBuildYear',
						isRequired: true,
						mask: '9999',
						validationType: 'minMax',
						maxValue: Number(dayjs().format('YYYY')) + 1,
						placeholder: 'Примерный год постройки / год приобретения',
						errorMessage: 'Введите год',
					},
				},
				{
					label: 'Наличие деревянных элементов в конструкции',
					field: {
						fieldType: 'radio',
						fieldName: `woodInConstruction`,
						defaultValue: RADIO_BOOLEAN[1].value,
						items: RADIO_BOOLEAN,
					},
				},
			],
		}),
		[formHook, loadingPledge, selectPledge]
	);

	const titleForm: IForm = React.useMemo(
		() => ({
			formHook,
			fields: [
				{
					label: 'Сколько лет собственники владеют объектом?',
					field: {
						fieldType: 'radio',
						fieldName: 'ownershipYears',
						defaultValue: RADIO_TITLE[1].value,
						items: RADIO_TITLE,
					},
				},
			],
		}),
		[formHook]
	);

	const borrowerForm: IForm = React.useMemo(
		() => ({
			formHook,
			hotReload: true,
			fields: [
				{
					label: 'Есть ли созаемщики по кредиту?',
					field: {
						fieldType: 'radio',
						fieldName: 'haveCoBorrower',
						defaultValue: RADIO_BOOLEAN[1].value,
						items: RADIO_BOOLEAN,
						onChange: changeHaveCoBorrowerHandler,
					},
				},
			],
		}),
		[changeHaveCoBorrowerHandler, formHook]
	);

	const risksForm: IForm = React.useMemo(
		() => ({
			formHook,
			fields: RISKS_SELECT.map<IFormField>(({ label, value }, index) => ({
				label: index === 0 ? 'Риски' : undefined,
				field: {
					fieldType: 'checkbox',
					fieldName: String(value),
					label,
					grid: 4,
					isDisabled: value === 'title' && !riskProperty,
					onChange: ({ target: { checked } }) => changeRisksHandler(checked, value),
				},
			})),
		}),
		[changeRisksHandler, formHook, riskProperty]
	);

	const precalculationForm: IForm = React.useMemo(
		() => ({
			formHook,
			fields: [
				{
					field: {
						fieldType: 'daData',
						daDataType: 'address',
						fieldName: 'dealCountry',
						placeholder: 'Населенный пункт сделки',
						validationType: 'addressWithCity',
						isRequired: true,
						onChange: () => {
							setTimeout(() => {
								formHook.trigger('dealCountry');
							}, 100);
						}
					},
				},
				{
					field: {
						fieldType: 'number',
						fieldName: 'summ',
						digitsAfterDot: 2,
						defaultValue: 5000000,
						placeholder: 'Размер кредита',
						validationType: 'minMax',
						maxValue: 1000000000,
						isRequired: true,
						errorMessage: 'Введите размер кредита',
					},
				},
				{
					field: {
						fieldType: 'enums',
						fieldName: 'banks',
						isLoading: loadingBanks,
						isRequired: true,
						errorMessage: 'Выберите кредитную организацию',
						items: selectBanks,
						placeholder: 'Наименование кредитной организации',
					},
				},
				...banksWithRaising,
				{
					field: {
						fieldType: 'date',
						fieldName: 'creditIssueDate',
						errorMessage: 'Выберите дату',
						placeholder: 'Дата кредитного договора',
					},
				},
				{
					isHidden: !isStaffMember || !agentsItems.length,
					field: {
						fieldName: "agentIsn",
						fieldType: "select",
						items: agentsItems,
						isSearchAvailable: true,
						isClearable: true,
						errorMessage: "Выберите агента",
						placeholder: "Агент"
					}
				},
			],
		}),
		[formHook, loadingBanks, selectBanks]
	);

	const value: PrecalculationContext = React.useMemo(
		() => ({
			goBackHandler: () => navigate(-1),
			onFormSubmit,
			currentTab: stateTabs || PROPERTY_TYPE[0],
			changeTabHandler: tab => setTab(tab),
			isShowHouseInProgress: stateTabs?.value === '0',
			isShowHouseReady: stateTabs?.value === '1',
			isLoading: isLoadingPrecalculation || isLoadingSaveAgreement || loadingDownloadPrecalc,
			isDisabled: isDisabledPrecalculation,
			precalculationForm,
			borrowerForm,
			borrowersItems,
			risksForm,
			isShowRiskLife: !!riskLife,
			isShowRiskProperty: !!riskProperty,
			isShowRiskTitle: !!riskTitle,
			propertyForm,
			titleForm,
			downloadAllHandler,
			downloadSelectedHandler,
			selectedLength: stateSelectedPrecalc.length,
			isLoadingResults: isLoadingPrecalculation,
			changeSelectedPrecalcHandler,
			selectBankHandler,
			resultItems,
			isDisabledSelectBank: !rightsHandler('createAgreement'),
		}),
		[
			onFormSubmit,
			stateTabs,
			isLoadingPrecalculation,
			isLoadingSaveAgreement,
			loadingDownloadPrecalc,
			isDisabledPrecalculation,
			precalculationForm,
			borrowerForm,
			borrowersItems,
			risksForm,
			riskLife,
			riskProperty,
			riskTitle,
			propertyForm,
			titleForm,
			downloadAllHandler,
			downloadSelectedHandler,
			stateSelectedPrecalc.length,
			changeSelectedPrecalcHandler,
			selectBankHandler,
			resultItems,
			rightsHandler,
			navigate,
			riskGroup0State,
			riskGroup1State
		]
	);

	return <PrecalculationContext.Provider value={value}>{children}</PrecalculationContext.Provider>;
};

export default React.memo(PrecalculationContextProvider);
