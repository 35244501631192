import styled  from "styled-components";

export const StyledPasswordRecoveryForm = styled.div`
  h1 {
    margin-bottom: 3rem;
  } 
  .nivaInput {
    margin-top: 2rem;
  }
  .linkButton {
    margin-top: 3rem;
  }
`;