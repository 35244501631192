import React, { createContext, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { IForm } from '../../../../../../../libs/uiKit/fieldsBuilder/types';
import { IModalProps } from '../../../../../../../libs/uiKit/modal/types';
import {useMutation} from "@apollo/client";
import {PasswordMutation, PasswordMutationVariables, SignInMutation} from "../../../../../../../libs/api/graphqlTypes";
import {PASSWORD} from "../../../../../../../libs/api/commands";
import useNotification from "../../../../../../widgets/notifier/ui/hooks/useNitification";
import {JSEncrypt} from "jsencrypt";

export type ChangePasswordContextProps = {
	children?: React.ReactNode;
} & IModalProps;

type ChangePasswordContext = {
	form: IForm;
	onFormSubmit: () => void;
	isLoading: boolean;
} & IModalProps;

type ChangePasswordForm = {
	oldPassword: string;
	newPassword: string;
	newPassword2: string;
};

const publicPasswordKey = process.env.REACT_APP_PASSWORD_KEY || 'unknown';
const encrypt = new JSEncrypt();
encrypt.setPublicKey(publicPasswordKey);

const ChangePasswordContext = createContext<ChangePasswordContext>({} as ChangePasswordContext);

export const useChangePasswordContext = (): ChangePasswordContext => useContext(ChangePasswordContext);

export const ChangePasswordContextProvider: React.FC<ChangePasswordContextProps> = props => {
	const { children, isOpen, onClose } = props;
	const formHook = useForm<ChangePasswordForm>({ mode: 'onBlur' });
	const [password, { loading: isLoading }] = useMutation<PasswordMutation, PasswordMutationVariables>(PASSWORD);
	const { setNotification } = useNotification();


	const onFormSubmit = formHook.handleSubmit(data => {
		if(data.newPassword !== data.newPassword2) {
			formHook.setError('newPassword2', {
				type: 'manual',
				message: 'Пароли не совпадают'
			});
			return;
		}
		password({
			variables: {
				old_password: encrypt.encrypt(data.oldPassword) || '',
				new_password: encrypt.encrypt(data.newPassword) || ''
			}
		}).then(() => {
			setNotification({type: 'success', text: 'Пароль успешно изменен'});
		}).catch((error) => {
			setNotification({type: 'error', text: error});
		});
		onClose();
	});

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			fields: [
				{
					field: {
						fieldType: 'input',
						fieldName: 'oldPassword',
						type: 'password',
						placeholder: 'Старый пароль',
						isRequired: true,
						errorMessage: 'Введите старый пароль',
						validationType: 'password',
					},
				},
				{
					field: {
						fieldType: 'input',
						fieldName: 'newPassword',
						type: 'password',
						placeholder: 'Новый пароль',
						isRequired: true,
						errorMessage: 'Введите новый пароль',
						validationType: 'password',
					},
				},
				{
					field: {
						fieldType: 'input',
						fieldName: 'newPassword2',
						type: 'password',
						placeholder: 'Подтвердите новый пароль',
						isRequired: true,
						errorMessage: 'Введите новый пароль',
						validationType: 'password',
					}
				}
			],
		}),
		[]
	);

	const value: ChangePasswordContext = React.useMemo(
		() => ({
			isLoading: false,
			onFormSubmit,
			form,
			isOpen,
			onClose,
		}),
		[form, isOpen, onClose, onFormSubmit]
	);

	return <ChangePasswordContext.Provider value={value}>{children}</ChangePasswordContext.Provider>;
};
