import React from 'react';
import { useLeftMenuContext } from './context';
import { StyledLeftMenu, StyledLeftMenuWrapper, StyledLogo, StyledNavigationLink, StyledNavigationLinksWrapper } from './styles';
import { LogoDesktop } from '../../../../../../../icons/logo/LogoDesktop';
import CopyrightIgniter from '../../../../../../widgets/copyright';

const LeftMenuUi: React.FC = () => {
	const { logoClickHandler, activeLinkIndex, links, isLeftMenuOpen } = useLeftMenuContext();

	return (
		<StyledLeftMenuWrapper isOpen={isLeftMenuOpen}>
			<StyledLeftMenu>
				<div>
					<StyledLogo onClick={logoClickHandler}>
						<LogoDesktop />
					</StyledLogo>
					<StyledNavigationLinksWrapper activeLink={activeLinkIndex < 0 ? undefined : activeLinkIndex}>
						{links.filter(({ isHidden }) => !isHidden).map(({ label, link, isHidden, clickHandler }, index) =>
							(
								<StyledNavigationLink
									onClick={(): void => {
										clickHandler(link);
									}}
									key={`nav-link-${index}`}
									isActive={index === activeLinkIndex}
								>
									{label}
								</StyledNavigationLink>
							))}
					</StyledNavigationLinksWrapper>
				</div>
				<CopyrightIgniter isAuthorization={false} />
			</StyledLeftMenu>
		</StyledLeftMenuWrapper>
	);
};

export default React.memo(LeftMenuUi);
