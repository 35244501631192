import { useQuery } from '@apollo/react-hooks';
import { MeQuery, MeQueryVariables } from '../../../../libs/api/graphqlTypes';
import { ME } from '../../../../libs/api/queries';

export type Rights =
	| 'statisticsPage'
	| 'applicationsLogPage'
	| 'agreementsLogPage'
	| 'underwriterLogPage'
	| 'settingsPage'
	| 'reportsPage'
	| 'manageUsers'
	| 'precalculationPage'
	| 'createApplicationPage'
	| 'applicationPage'
	| 'underwritingPage'
	| 'agreementPage'
	| 'invoicePage'
	| 'approveRisks'
	| 'editAgreement'
	| 'setDiscount'
	| 'createAgreement'
	| 'underwritingRiskProperty'
	| 'underwritingRiskLife'
	| 'underwritingRiskTitle'
	| 'prolongationsLogPage'
	| 'searchContragentByIsn'
	| 'sendAgreement'
	| 'promocode'
	| 'precalcChangeAgent'
	| 'agreementRiskChange'
	| 'prolongationRiskChange'
	| 'applyAllowance'
	| 'prolongationKvDiscount'
	| 'unifiedRatesCalculation'
  | 'marketingField'
	| 'prolongationNeedSms'
	| 'prolongationNeedVerify'
	| 'confirmLetter'
	| 'lifeTotalTariff'
	| 'unknown';

export const rightsHandler = (name: Rights, permissions: string[]): boolean => {
	if (!name) return false;
	if (permissions.includes('*')) return true;

	switch (name) {
		case 'statisticsPage':
			return Boolean(permissions.find(permission => ['statisticsPage'].includes(permission)));
		case 'settingsPage':
			return Boolean(permissions.find(permission => ['settingsPage'].includes(permission)));
		case 'applicationsLogPage':
			return Boolean(permissions.find(permission => ['applicationsLogPage'].includes(permission)));
		case 'agreementsLogPage':
			return Boolean(permissions.find(permission => ['agreementsLogPage'].includes(permission)));
		case 'underwriterLogPage':
			return Boolean(permissions.find(permission => ['underwriterLogPage'].includes(permission)));
		case 'manageUsers':
			return Boolean(permissions.find(permission => ['manageUsers'].includes(permission)));
		case 'reportsPage':
			return Boolean(permissions.find(permission => ['reportsPage'].includes(permission)));
		case 'precalculationPage':
			return Boolean(permissions.find(permission => ['precalculationPage'].includes(permission)));
		case 'createApplicationPage':
			return Boolean(permissions.find(permission => ['createApplicationPage'].includes(permission)));
		case 'invoicePage':
			return Boolean(permissions.find(permission => ['invoicePage'].includes(permission)));
		case 'underwritingPage':
			return Boolean(permissions.find(permission => ['underwritingPage'].includes(permission)));
		case 'applicationPage':
			return Boolean(permissions.find(permission => ['applicationPage'].includes(permission)));
		case 'agreementPage':
			return Boolean(permissions.find(permission => ['agreementPage'].includes(permission)));
		case 'underwritingRiskProperty':
			return Boolean(permissions.find(permission => ['underwritingRiskProperty'].includes(permission)));
		case 'underwritingRiskLife':
			return Boolean(permissions.find(permission => ['underwritingRiskLife'].includes(permission)));
		case 'underwritingRiskTitle':
			return Boolean(permissions.find(permission => ['underwritingRiskTitle'].includes(permission)));
		case 'approveRisks':
			return Boolean(permissions.find(permission => ['underwritingRiskTitle', 'underwritingRiskLife', 'underwritingRiskProperty'].includes(permission)));
		case 'editAgreement':
			return Boolean(permissions.find(permission => ['editAgreement'].includes(permission)));
		case 'createAgreement':
			return Boolean(permissions.find(permission => ['createAgreement'].includes(permission)));
		case 'setDiscount':
			return Boolean(permissions.find(permission => ['setDiscount'].includes(permission)));
		case 'prolongationsLogPage':
			return Boolean(permissions.find(permission => ['prolongationsLogPage'].includes(permission)));
		case 'searchContragentByIsn':
			return Boolean(permissions.find(permission => ['searchContragentByIsn'].includes(permission)));
		case 'sendAgreement':
			return Boolean(permissions.find(permission => ['sendAgreement'].includes(permission)));
		case 'promocode':
			return Boolean(permissions.find(permission => ['promocode'].includes(permission)));
		case 'precalcChangeAgent':
			return Boolean(permissions.find(permission => ['precalcChangeAgent'].includes(permission)));
		case 'agreementRiskChange':
			return Boolean(permissions.find(permission => ['agreementRiskChange'].includes(permission)));
		case 'prolongationRiskChange':
			return Boolean(permissions.find(permission => ['prolongationRiskChange'].includes(permission)));
		case 'applyAllowance':
			return Boolean(permissions.find(permission => ['applyAllowance'].includes(permission)));
		case 'prolongationKvDiscount':
			return Boolean(permissions.find(permission => ['prolongationKvDiscount'].includes(permission)));
		case 'unifiedRatesCalculation':
			return Boolean(permissions.find(permission => ['unifiedRatesCalculation'].includes(permission)));
		case 'marketingField':
			return Boolean(permissions.find(permission => ['marketingField'].includes(permission)));
		case 'prolongationNeedSms':
			return Boolean(permissions.find(permission => ['prolongationNeedSms'].includes(permission)));
		case 'prolongationNeedVerify':
			return Boolean(permissions.find(permission => ['prolongationNeedVerify'].includes(permission)));
		case 'confirmLetter':
			return Boolean(permissions.find(permission => ['confirmLetter'].includes(permission)));
		case 'lifeTotalTariff':
			return Boolean(permissions.find(permission => ['lifeTotalTariff'].includes(permission)));
		default:
			return false;
	}
};

export const useUserRights = (): { loading: boolean; rightsHandler: (name: Rights) => boolean } => {
	const { data: dataUser, loading } = useQuery<MeQuery, MeQueryVariables>(ME);

	const checkRight = (name: Rights): boolean => rightsHandler(name, dataUser?.me?.user?.permissions || []);

	return {
		rightsHandler: checkRight,
		loading,
	};
};
