import React from 'react';
import MainLayoutIgniter from '../../../../common/mainLayout';
import {
	StyledRoleByIdHeader,
	StyledRoleByIdTitle,
	StyledRoleContentWrapper,
	StyledRoleForm,
	StyledRoleFunctions,
	StyledRoleName,
	StyledSelectedFunctionItem,
	StyledSelectedFunctionsArea,
} from './styles';
import Button from '../../../../../../libs/uiKit/button';
import { ArrowIcon } from '../../../../../../icons/ui/Arrow';
import { Heading2, Heading3, TextButton } from '../../../../../../libs/uiKit/globalStyledComponents';
import FlyButtons from '../../../../../../libs/uiKit/flyButtons';
import { StyledButtonsGroupAutoSize } from '../../../ui/styles';
import { useRoleByIdContext } from './context';
import { FieldsBuilder } from '../../../../../../libs/uiKit/fieldsBuilder';
import { CloseIcon } from '../../../../../../icons/ui/Close';
import DeleteRoleIgniter from '../../../widgets/deleteRole';
import RoleUsersPopup from "./RoleUsersPopup";

const RoleByIdUi: React.FC = () => {
	const {
		roleName,
		isLoading,
		deleteRoleHandler,
		goBackHandler,
		onFormSubmit,
		formRoleName,
		formPermissions,
		deletePermissionHandler,
		permissionsToShow,
		showMoreHandler,
		isShowShowMore,
		showMoreText,
		deleteRoleId,
		closeDeleteRoleHandler,
		deleteRoleCallback,
		isDisabledEdit,
		roleUsersCount,
	} = useRoleByIdContext();

	const [stateUsersSameRole, setUsersSameRole] = React.useState<boolean>(false);

	return (
		<MainLayoutIgniter>
			<StyledRoleByIdHeader>
				<StyledRoleByIdTitle>
					<Button appearance='text' onClick={goBackHandler} iconBefore={<ArrowIcon direction='left' />}>
						Роли и группы
					</Button>
					<Heading2>{roleName}</Heading2>
				</StyledRoleByIdTitle>

				<FlyButtons isWhiteBackground={true}>
					<StyledButtonsGroupAutoSize>
						<Button isDisabled={isDisabledEdit} isLoading={isLoading} type='submit' form='roleByIdChangeForm' appearance='filled'>
							Сохранить роль
						</Button>
						<Button isDisabled={isDisabledEdit} isLoading={isLoading} onClick={deleteRoleHandler} appearance='transparent'>
							Удалить роль
						</Button>
					</StyledButtonsGroupAutoSize>
				</FlyButtons>
			</StyledRoleByIdHeader>
			<form onSubmit={onFormSubmit} id='roleByIdChangeForm'>
				<StyledRoleName>
					<div>
						<Heading3>Название роли</Heading3>
						<FieldsBuilder {...formRoleName} />
					</div>
					<div>
						<Heading3>Количество пользоватетелей с данной ролью: <Button appearance='link' type='button' onClick={() => setUsersSameRole(true)}>{roleUsersCount}</Button> </Heading3>
					</div>
				</StyledRoleName>

				<StyledRoleContentWrapper>
					<StyledRoleForm>
						<Heading3>Доступные функции</Heading3>
						<FieldsBuilder {...formPermissions} />
					</StyledRoleForm>

					<StyledRoleFunctions>
						<Heading3>Назначенные функции</Heading3>
						<StyledSelectedFunctionsArea>
							{!!permissionsToShow.length && (
								<>
									<div>
										{permissionsToShow.map(({ label, value }, index) => (
											<StyledSelectedFunctionItem key={`func-${index}`}>
												<TextButton>{label}</TextButton>
												<Button onClick={(): void => deletePermissionHandler(value)} appearance='icon' icon={<CloseIcon />} isDisabled={isDisabledEdit} />
											</StyledSelectedFunctionItem>
										))}
									</div>
									{isShowShowMore && (
										<Button onClick={showMoreHandler} appearance='link'>
											{showMoreText}
										</Button>
									)}
								</>
							)}
							{!permissionsToShow.length && <TextButton>Нет назначенных функций</TextButton>}
						</StyledSelectedFunctionsArea>
					</StyledRoleFunctions>
				</StyledRoleContentWrapper>
			</form>
			<DeleteRoleIgniter id={deleteRoleId} isOpen={!!deleteRoleId} onClose={closeDeleteRoleHandler} header='Удалить роль' deleteCallback={deleteRoleCallback} />
			<RoleUsersPopup onClose={() => setUsersSameRole(false) } isOpen={stateUsersSameRole} />
		</MainLayoutIgniter>
	);
};

export default React.memo(RoleByIdUi);
