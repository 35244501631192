import React, { createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useGlobalContext } from '../../../../../../../../apps/main/ui/GlobalContext';
import { useMainLayoutContext } from '../../../../ui/context';
import { PersonIcon } from '../../../../../../../../icons/ui/Person';
import { QuestionIcon } from '../../../../../../../../icons/ui/Question';
import { LogOutIcon } from '../../../../../../../../icons/ui/LogOut';
import { LOGOUT } from '../../../../../../../../libs/api/commands';
import useNotification from '../../../../../../../widgets/notifier/ui/hooks/useNitification';
import useOnboarding from '../../../../../../../widgets/onboarding/hooks/useNitification';
import {BASE_URL} from "../../../../../../../../libs/utils/staticData";

type LinkItem = { icon: React.ReactNode; isLoading: boolean; isHidden: boolean; clickHandler: () => void };

type HeaderContext = {
	switchLeftMenuHandler: () => void;
	username: string;
	links: LinkItem[];
	isUnderAdmin: boolean;
};

const HeaderContext = createContext<HeaderContext>({} as HeaderContext);

export const useHeaderContext = (): HeaderContext => useContext(HeaderContext);

export const HeaderContextProvider: React.FC = ({ children }) => {
	const {
		user: { type, username, isUnderAdmin },
		routes: { profile, redirectToAuthorization },
	} = useGlobalContext();
	const { switchOnboarding } = useOnboarding();
	const { switchLeftMenuHandler } = useMainLayoutContext();
	const navigate = useNavigate();
	const { setNotification } = useNotification();

	const [logout, { loading: loadingLogout }] = useMutation(LOGOUT);

	const logoutHandler = React.useCallback(() => {
		logout()
			.then(() => {
				window.location.href = BASE_URL ? `${BASE_URL}/authorization` : '/authorization';
			})
			.catch(error => setNotification({ type: 'error', text: error }));
	}, [redirectToAuthorization, logout, setNotification]);

	const links: LinkItem[] = React.useMemo(
		() => [
			{ icon: <PersonIcon />, clickHandler: () => navigate(profile()), isLoading: false, isHidden: false },
			{
				icon: <QuestionIcon />,
				clickHandler: (): void => switchOnboarding(true),
				isHidden: type === 'client',
				isLoading: false,
			},
			{
				icon: <LogOutIcon />,
				clickHandler: logoutHandler,
				isLoading: loadingLogout,
				isHidden: false,
			},
		],
		[type, logoutHandler, loadingLogout, navigate, profile, switchOnboarding]
	);

	const value: HeaderContext = React.useMemo(
		() => ({
			links,
			switchLeftMenuHandler,
			username: username || '',
			isUnderAdmin,
		}),
		[links, username, switchLeftMenuHandler]
	);

	return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>;
};
