import React, { createContext, useContext } from 'react';
import { ITabItem } from '../../../../../libs/uiKit/tabs/types';
import { UserProps } from '../../widgets/createUser/ui/context';
import { GroupProps } from '../../widgets/createGroup/ui/context';
import useNotification from '../../../../widgets/notifier/ui/hooks/useNitification';
import {useGlobalContext} from "../../../../../apps/main/ui/GlobalContext";
import {useUserRights} from "../../../common/hooks/useUserRights";
import {useNavigate} from "react-router-dom";

type RolesContext = {
	isShowRoles: boolean;
	isShowGroups: boolean;
	changeTabHandler: (value: ITabItem | undefined) => void;
	activeTab: ITabItem;

	openCreateUserHandler: (data: UserProps) => void;
	openCreateGroupHandler: (data: GroupProps) => void;
	openCreateRoleHandler: () => void;
	openDeleteUserHandler: (id: number | undefined | null) => void;
	openDeleteGroupHandler: (id: number | undefined | null) => void;
	openDeleteRoleHandler: (id: number | undefined | null) => void;
	createUserModalData: UserProps | null;
	createGroupModalData: GroupProps | null;
	isOpenCreateRoleModal: boolean;
	deleteUserId: number | undefined;
	deleteGroupId: number | undefined;
	deleteRoleId: number | undefined;
	closeCreateUserModal: () => void;
	closeCreateGroupModal: () => void;
	closeCreateRoleModal: () => void;
	closeDeleteUserModal: () => void;
	closeDeleteGroupModal: () => void;
	closeDeleteRoleModal: () => void;
};

export const ROLES_TABS: ITabItem[] = [
	{ label: 'Роли пользователей', value: 'roles' },
	{ label: 'Группы пользователей', value: 'groups' },
];
const activeTab: ITabItem | undefined = ROLES_TABS.find(({ value }) => value === localStorage.getItem('roleTab'));

const RolesContext = createContext<RolesContext>({} as RolesContext);

export const useRolesContext = (): RolesContext => useContext(RolesContext);

const RolesContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { setNotification } = useNotification();
	const { rightsHandler } = useUserRights();
	const navigate = useNavigate();
	const [stateTab, setTab] = React.useState<ITabItem>(activeTab || ROLES_TABS[1]);

	const {
		user: {
			isStaffMember,
			isAdmin,
			isSuper
		}
	} = useGlobalContext();

	if(!(isSuper || isStaffMember || isAdmin)){
		navigate('/');
	}

	const [stateCreateRoleModal, setCreateRoleModal] = React.useState<boolean>(false);
	const [stateDeleteUserModal, setDeleteUserModal] = React.useState<number | undefined>(undefined);
	const [stateDeleteGroupModal, setDeleteGroupModal] = React.useState<number | undefined>(undefined);
	const [stateDeleteRoleModal, setDeleteRoleModal] = React.useState<number | undefined>(undefined);
	const [stateCreateUserModal, setCreateUserModal] = React.useState<UserProps | null>(null);
	const [stateCreateGroupModal, setCreateGroupModal] = React.useState<GroupProps | null>(null);

	const changeTabHandler = React.useCallback((tab: ITabItem | undefined): void => {
		if (!tab) return;
		localStorage.setItem('roleTab', String(tab.value));
		setTab(tab);
	}, []);

	const openDeleteUserHandler = React.useCallback(
		id => {
			if (typeof id !== 'number') return setNotification({ type: 'error', text: 'no user id' });
			setDeleteUserModal(id);
		},
		[setNotification]
	);
	const openDeleteGroupHandler = React.useCallback(
		id => {
			if (typeof id !== 'number') return setNotification({ type: 'error', text: 'no group id' });
			setDeleteGroupModal(id);
		},
		[setNotification]
	);
	const openDeleteRoleHandler = React.useCallback(
		id => {
			if (typeof id !== 'number') return setNotification({ type: 'error', text: 'no role id' });
			setDeleteRoleModal(id);
		},
		[setNotification]
	);

	const value: RolesContext = React.useMemo(
		() => ({
			isShowGroups: stateTab.value === 'groups',
			isShowRoles: stateTab.value === 'roles',
			changeTabHandler,
			activeTab: stateTab,

			openCreateUserHandler: data => setCreateUserModal(data),
			openCreateGroupHandler: data => setCreateGroupModal(data),
			openCreateRoleHandler: () => setCreateRoleModal(true),
			openDeleteUserHandler,
			openDeleteGroupHandler,
			openDeleteRoleHandler,
			createUserModalData: stateCreateUserModal,
			createGroupModalData: stateCreateGroupModal,
			isOpenCreateRoleModal: stateCreateRoleModal,
			deleteUserId: stateDeleteUserModal,
			deleteGroupId: stateDeleteGroupModal,
			deleteRoleId: stateDeleteRoleModal,
			closeCreateUserModal: () => setCreateUserModal(null),
			closeCreateGroupModal: () => setCreateGroupModal(null),
			closeCreateRoleModal: () => setCreateRoleModal(false),
			closeDeleteUserModal: () => setDeleteUserModal(undefined),
			closeDeleteGroupModal: () => setDeleteGroupModal(undefined),
			closeDeleteRoleModal: () => setDeleteRoleModal(undefined),
		}),
		[
			changeTabHandler,
			openDeleteGroupHandler,
			openDeleteRoleHandler,
			openDeleteUserHandler,
			stateCreateGroupModal,
			stateCreateRoleModal,
			stateCreateUserModal,
			stateDeleteGroupModal,
			stateDeleteRoleModal,
			stateDeleteUserModal,
			stateTab,
		]
	);

	return <RolesContext.Provider value={value}>{children}</RolesContext.Provider>;
};

export default React.memo(RolesContextProvider);
