import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';
import {useQuery} from "@apollo/react-hooks";
import { ISelectItem } from '../../../../../libs/uiKit/select/types';
import { PrecalculationForm } from '../../ui/context';
import { DEFAULT_INSURANCE_TYPE, DEFAULT_RISKS } from '../../../../../libs/utils/staticData';
import {
	MeQuery, MeQueryVariables,
	PrecalcMutation,
	PrecalcMutationVariables,
	SaveAgreementMutation,
	SaveAgreementMutationVariables
} from '../../../../../libs/api/graphqlTypes';
import { SAVE_AGREEMENT_DRAFT } from '../../../../../libs/api/commands';
import useNotification from '../../../../widgets/notifier/ui/hooks/useNitification';
import { ITabItem } from '../../../../../libs/uiKit/tabs/types';
import {ME, PRECALCULATION} from '../../../../../libs/api/queries';
import {isMoRegion, isNewRegion} from "../../../../../libs/utils/address";

type createAgreementCmdProps = {
	bank?: ISelectItem;
	formData: PrecalculationForm;
	tabValue?: ITabItem;
	callback: (isn: string) => void;
	expressMode?: boolean;
};

type precalculationCmdProps = {
	tabValue?: ITabItem;
	formData: PrecalculationForm;
	callback: () => void;
};

const usePrecalculationCommands = (): {
	createAgreementCmd: (cmd: createAgreementCmdProps) => void;
	isLoadingSaveAgreement: boolean;
	precalculationCmd: (cmd: precalculationCmdProps) => void;
	isLoadingPrecalculation: boolean;
	dataPrecalculation: PrecalcMutation | null | undefined;
} => {
	const { setNotification } = useNotification();
	const { data: dataUser } = useQuery<MeQuery, MeQueryVariables>(ME);

	const [saveAgreement, { loading: loadingSaveAgreement }] = useMutation<SaveAgreementMutation, SaveAgreementMutationVariables>(SAVE_AGREEMENT_DRAFT);
	const [precalculation, { data: dataPrecalculation, loading: loadingPrecalculation }] = useMutation<PrecalcMutation, PrecalcMutationVariables>(PRECALCULATION);

	const createAgreementCmd = ({ bank, formData, tabValue, callback, expressMode }: createAgreementCmdProps): void => {
		const { life, property, title, borrowers = [], objectBuildYear, objectType, summ, creditIssueDate, dealCountry } = formData;
		const contragent = borrowers[0];
		const coBorrower = borrowers[1];

		// @ts-ignore
		const rate:number|null = formData[String(`rate-${bank.value}`)];

		const risks =
			tabValue?.value === '1'
				? {
						riskLife: life,
						riskProperty: property,
						riskTitle: title,
				  }
				: DEFAULT_RISKS;
		saveAgreement({
			variables: {
				calcForm: {
					...risks,
					isHouseFinished: tabValue?.value === '1',
					insuranceType: DEFAULT_INSURANCE_TYPE,
					policyholder: {
						birthday: contragent?.birthDate ? dayjs(contragent?.birthDate).format('DD.MM.YYYY') : null,
						sex: contragent?.sex,
						employment: {
							riskGroup: contragent?.riskGroup,
							profession: contragent?.profession,
						}
					},
					credit: {
						city: dealCountry,
						insuranceType: DEFAULT_INSURANCE_TYPE,
						creditSum: summ,
						creditIssueDate: creditIssueDate ? dayjs(creditIssueDate).format('DD.MM.YYYY') : null,
						borrowerShare: Number(contragent?.share) || 0,
						bank: bank || null,
						creditRate: rate,
						birthDateMethod: contragent?.birthDateMethod || { value: '0', label: 'По году рождения' },
					},
					coBorrowers: coBorrower
						? [
								{
									birthday: coBorrower?.birthDate ? dayjs(coBorrower?.birthDate).format('DD.MM.YYYY') : null,
									share: Number(coBorrower?.share) || 0,
									sex: coBorrower?.sex,
									employment: {
										riskGroup: coBorrower?.riskGroup,
										profession: coBorrower?.profession,
									}
								},
						  ]
						: [],
					propertyObjects: property
						? [
								{
									propertyPledge: objectType || null,
									buildYear: Number(objectBuildYear) || null,
									pledgeShare: 100
								},
						  ]
						: [],
					expressMode: expressMode || false,
				},
			},
		})
			.then(({ data }) => {
				if (!data?.saveAgreement) return setNotification({ type: 'error', text: 'empty agreement id' });
				callback(data.saveAgreement);
			})
			.catch(error => setNotification({ type: 'error', text: error }));
	};

	const precalculationCmd = ({ formData, tabValue, callback }: precalculationCmdProps): void => {
		const { summ, banks, haveCoBorrower, borrowers = [], objectType, objectBuildYear, woodInConstruction, ownershipYears, life, property, title, creditIssueDate, dealCountry, agentIsn } = formData;
		const borrower = borrowers[0];
		const coBorrower = borrowers[1];

		if(!!dealCountry && dealCountry?.data?.city){
			if(isMoRegion(dealCountry) && dataUser?.me?.user?.group?.is_filial || isNewRegion(dealCountry)){
				return setNotification({type: "error", text: "Расчет по выбранным параметрам невозможен. Обратитесь к куратору!"});
			}
		}

		const risks =
			tabValue?.value === '1'
				? {
						riskLife: life,
						riskTitle: title,
						riskProperty: property,
				  }
				: DEFAULT_RISKS;

		precalculation({
			variables: {
				precalcForm: {
					...risks,
					insuranceType: DEFAULT_INSURANCE_TYPE,
					creditSum: summ,
					creditIssueDate: creditIssueDate ? dayjs(creditIssueDate).format('DD.MM.YYYY') : null,
					banks: banks?.map(bank => {
						// @ts-ignore
						const rate:number|null = formData[String(`rate-${bank.value}`)];
						return {
							isn: String(bank.value),
							rate,
						};
					}),
					isHouseFinished: tabValue?.value === '1',
					borrowerBirthday: borrower?.birthDate ? dayjs(borrower?.birthDate).format('DD.MM.YYYY') : null,
					borrowerSex: borrower?.sex,
					borrowerShare: Number(borrower?.share) || null,
					borrowerHasChronicDiseases: borrower?.isChronical === 'true',
					borrowerRiskGroup: borrower?.riskGroup,
					borrowerProfession: borrower?.profession,
					borrowerBirthdayMethod: borrower?.birthDateMethod,
					withCoBorrowers: haveCoBorrower === 'true',
					coBorrowerBirthday: coBorrower?.birthDate ? dayjs(coBorrower?.birthDate).format('DD.MM.YYYY') : null,
					coBorrowerSex: coBorrower?.sex,
					coBorrowerShare: Number(coBorrower?.share) || null,
					coBorrowerHasChronicDiseases: coBorrower?.isChronical === 'true',
					coBorrowerRiskGroup: coBorrower?.riskGroup,
					coBorrowerProfession: coBorrower?.profession,
					coBorrowerBirthdayMethod: coBorrower?.birthDateMethod,
					objectType,
					buildYear: Number(objectBuildYear) || null,
					ownershipYears: Number(ownershipYears) || null,
					dealCountry,
					woodInConstruction: woodInConstruction === 'true',
					agent: agentIsn
				},
			},
		})
			.then(() => callback())
			.catch(error => setNotification({ type: 'error', text: error }));
	};

	return {
		createAgreementCmd,
		isLoadingSaveAgreement: loadingSaveAgreement,
		precalculationCmd,
		isLoadingPrecalculation: loadingPrecalculation,
		dataPrecalculation,
	};
};

export default usePrecalculationCommands;
