import { useEffect, useState } from 'react';

export const useKeyPress = (targetKey: string): boolean => {
	const [keyPressed, setKeyPressed] = useState<boolean>(false);

	const downHandler = ({ key }: { key: string }): void => {
		if (key === targetKey) {
			setKeyPressed(true);
			setKeyPressed(false);
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', downHandler);

		return (): void => {
			window.removeEventListener('keydown', downHandler);
		};
	});

	return keyPressed;
};
