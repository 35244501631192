import styled from 'styled-components';

export const WrapperDaData = styled.div`
	position: relative;

	.listShow {
		pointer-events: unset;
		opacity: 1;
	}

	.listHide {
		pointer-events: none;
		opacity: 0;
	}
`;
