import styled  from "styled-components";

export const StyledRolesHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
`;

export const StyledButtonsGroupAutoSize = styled.div`
  display: flex;
  button {
    margin-right: 1rem;
  }
  
  button:last-child {
    margin: 0;
  }
    @media screen and (max-width: 767px) {
  button {
    width: calc(50% - 0.5rem);
    margin-right: 1rem;
  }
  
  button:last-child {
    margin: 0;
  }
  }
`;

export const StyledButtonsGroup = styled.div`
  width: 100%;
  
  button {
    width: calc(50% - 0.5rem);
    margin-right: 1rem;
  }
  
  button:last-child {
    margin: 0;
  }
`;
export const StyledFullSizeOnMobileButton = styled.div`
  @media screen and (max-width: 767px) {
    > button {
      width: 100%;
    }
  }
`;

export const StyledGroupHistoryWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 9fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1.5rem;
`;
export const StyledGroupHistoryTableWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1.5rem;
`;

export const StyledGroupHistoryHeaderItem = styled.div`
  opacity: 0.7;
`;
