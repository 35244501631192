import React, { useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';
import { StyledCloseButton, StyledModal, StyledModalBackground, StyledModalWrapper } from './styles';
import Loader from '../loader';
import { CloseIcon } from '../icons/Close';
import { ANIMATION_SEC, Heading2 } from '../globalStyles';
import { IModalProps } from './types';

const ModalContent: React.FC<IModalProps> = props => {
	const { header, width, onClose, children, isLoading, afterHeaderContent, onCloseByX } = props;

	const onEscapePress = useCallback(
		({ key }: KeyboardEvent) => {
			if (key !== 'Escape') return;
			onClose();
		},
		[onClose]
	);

	React.useEffect(() => {
		document.body.style.overflow = 'hidden';
		window.addEventListener('keyup', onEscapePress);
		return (): void => {
			document.body.style.overflow = 'unset';
			window.removeEventListener('keyup', onEscapePress);
		};
	}, [onEscapePress]);

	return (
		<StyledModalWrapper>
			<StyledModalBackground onClick={onClose} />
			<StyledModal width={typeof width === 'number' ? width : 55}>
				<StyledCloseButton onClick={() => {
					onClose();
					if(onCloseByX) onCloseByX();
				}}>
					<CloseIcon />
				</StyledCloseButton>
				{header && <Heading2>{header}{afterHeaderContent}</Heading2>}
				{!!isLoading && <Loader />}
				{!isLoading && children}
			</StyledModal>
		</StyledModalWrapper>
	);
};

export const Modal: React.FC<IModalProps> = props => {
	const { isOpen } = props;
	return (
		<CSSTransition in={isOpen} timeout={ANIMATION_SEC * 1000} classNames='modal' mountOnEnter unmountOnExit>
			<ModalContent {...props} />
		</CSSTransition>
	);
};

export default React.memo(Modal);
