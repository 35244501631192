import React from 'react';

export const  LogInIcon: React.FC = () => (
	<svg className="svg-icon" width="1em" height="2.1rem" fill="currentColor"
		 viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M256 721.454545 256 1024 954.181818 1024 954.181818 0 256 0 256 302.545455 302.545455 302.545455 302.545455 46.545455 907.636364 46.545455 907.636364 977.454545 302.545455 977.454545 302.545455 721.454545Z" />
		<path
			d="M69.818182 535.179636 663.272727 535.179636 535.272727 744.727273 581.818182 744.727273 721.454545 512 581.818182 279.272727 535.272727 279.272727 663.272727 488.727273 69.818182 488.727273Z"/>
	</svg>
);
